import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {
  CLEAN_ROOM_STAGES,
  CLEAN_ROOM_STATUS,
  CleanRoomTypes,
  Ownership,
} from 'components/CleanRooms/constants';
import { Link } from 'react-router-dom';
import { Typography, styled } from '@mui/material';
import { createCrPartnerUrl } from 'components/CleanRooms/CrCard';
import { habuColors } from 'Theme';


export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.text.link,
  pointerEvents: 'auto',
  cursor: 'pointer',
  ...theme.components.MuiLink.styleOverrides.root,
}));

export const DisabledLink = styled(Typography)({
  color: habuColors.neutral[60],
  cursor: 'default',
});

export const AddPartnerLink = ({ crId, cleanRoom }) => {
  const isAuthorized = PermissionService.isAuthorized(['CleanRoomsAdmin']);
  const isOwner = cleanRoom?.ownership === Ownership.OWNER.key;
  const isCompleted = cleanRoom?.stage === CLEAN_ROOM_STAGES.COMPLETE.key;
  const isExpired = cleanRoom?.status === CLEAN_ROOM_STATUS.EXPIRED.key;

  // aws clean room
  const isPending = cleanRoom?.stage === CLEAN_ROOM_STAGES.PENDING.key;
  const isReady = cleanRoom?.stage === CLEAN_ROOM_STAGES.READY.key;
  const isProvisioning = cleanRoom?.stage === CLEAN_ROOM_STAGES.PROVISIONING.key;
  const isAwsCleanRoom = cleanRoom?.type === CleanRoomTypes.AWS_CLEAN_ROOM;

  const link = (
    <StyledLink to={createCrPartnerUrl(crId)}>
      Add Partner
    </StyledLink>
  );

  const disabledLink = (
    <DisabledLink>
      Add Partner
    </DisabledLink>
  );

  const renderLink = () => {
    if (isOwner && isCompleted && isAuthorized) {
      return isExpired ? disabledLink : link;
    }
    if ((isProvisioning || isCompleted) && isAwsCleanRoom) {
      return isExpired ? disabledLink : link;
    }
    if ((isPending || isReady) && isAwsCleanRoom) {
      return isExpired ? disabledLink : link;
    }
    return null;
  };

  return (
    <Tooltip title={isExpired ? 'Clean Room Expired. Unable to add partner.' : ''}>
      <span>{renderLink()}</span>
    </Tooltip>
  );
};
