import {
  useCallback, useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Custom hook to get and set query parameters in the URL.
 * @returns {Array} - [queryParams, setQueryParams]
 * queryParams: An object of key-value pairs for query parameters.
 * setQueryParams: A function to update the query parameters in the URL.
 */
export const useSearchParams = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  // Parse query parameters from the URL
  const getQueryParams = useCallback(() => {
    const urlParams = new URLSearchParams(search);
    const params = {};
    urlParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [search]);

  const queryParams = useMemo(() => getQueryParams(), [getQueryParams]);

  // Function to update query parameters in the URL
  const setQueryParams = useCallback((newParams) => {
    const urlParams = new URLSearchParams(search);

    // Update or add the new query parameters
    Object.entries(newParams)
      .forEach(([key, value]) => {
        if (value === undefined || value === null) {
          urlParams.delete(key);
        }
        else {
          urlParams.set(key, value);
        }
      });

    history.replace({ pathname, search: urlParams.toString() });
  }, [history, pathname, search]);

  return [queryParams, setQueryParams];
};
