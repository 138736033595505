import React, { useContext } from 'react';
import { GenerateAPIKeyContext } from 'pages/APIKeyManagement/GenerateAPIKeyProvider';
import { Typography } from '@mui/material';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { motifBaseTheme } from 'MotifTheme';
import { useSelector } from 'react-redux';


export const CreatedAPIKeyDetailsLayout = ({
  createdAPIKey,
}) => {
  const { name: orgName } = useSelector(activeOrgSelector);
  const {
    isGeneratedApiKeySuccess,
    isRotated,
  } = useContext(GenerateAPIKeyContext);

  return (
    <>
      <Typography variant='body2' marginBottom={4}>
        {`An API key has been generated for ${createdAPIKey.PartnerDisplayName || orgName}. Copy this API secret now.`}
      </Typography>
      <Typography variant='body2' marginBottom={4}>
        It will only be shown once.
      </Typography>
      <Typography
        variant='body2'
        marginBottom={4}
        style={{
          backgroundColor: motifBaseTheme.palette.success['x-light'],
          padding: '15px',
          borderRadius: '8px',
        }}
      >
        {(isGeneratedApiKeySuccess || isRotated) && createdAPIKey?.ClientSecret}
      </Typography>
    </>
  );
};
