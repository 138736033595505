import React, { useContext, useMemo } from 'react';
import {
  CreatedAPIKeyDetailsLayout,
} from 'pages/APIKeyManagement/GenerateAPIKeyModalLayout/CreatedAPIKeyDetailsLayout';
import { GenerateAPIKeyContext } from 'pages/APIKeyManagement/GenerateAPIKeyProvider';
import {
  GenerateAPIKeyDetailsLayout,
} from 'pages/APIKeyManagement/GenerateAPIKeyModalLayout/GenerateAPIKeyDetailsLayout';
import { HDialog, HDialogContent } from 'BaseComponents';


export const GenerateAPIKeyModalLayout = () => {
  const {
    openModal,
    handleSubmit,
    handleCancel,
    generatingKey,
    steps,
    createdAPIKey,
    rotatingKey,
  } = useContext(GenerateAPIKeyContext);

  const btnObject = useMemo(() => {
    if (steps === -1 || steps === 0) {
      return { submitText: 'Generate', cancelText: 'Cancel' };
    }

    if (steps === 1) {
      return { submitText: 'Copy To Clipboard', cancelText: 'Cancel' };
    }

    return {};
  }, [steps]);

  return (
    <HDialog
      open={openModal}
      fullWidth
      maxWidth='sm'
      onClose={handleCancel}
      onSubmit={handleSubmit}
      submitText={btnObject.submitText}
      cancelText={btnObject.cancelText}
      loading={generatingKey || rotatingKey}
      title='Generate API Key'
    >
      <HDialogContent>
        {steps === 1 && (
          <CreatedAPIKeyDetailsLayout createdAPIKey={createdAPIKey} />
        )}
        {steps === 0 && (
          <GenerateAPIKeyDetailsLayout />
        )}
      </HDialogContent>
    </HDialog>
  );
};
