import Divider from '@mui/material/Divider';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from '@mui/material/Grid';
import MoreButton from 'components/CleanRooms/MoreButton';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {
  BlankCard,
  CardHeaderStatus,
  CardKeyValueRow,
  CardTitleRow,
} from 'components/Common/CardStructures';
import {
  CLEAN_ROOM_STAGES,
  CLEAN_ROOM_STATUS,
  CleanRoomTypes,
  Ownership,
} from 'components/CleanRooms/constants';
import { CleanRoomsLinkContext } from 'pages/CleanRooms/CleanRoomsLinkContextProvider';
import { crPartners } from 'utils/spaUrls';
import {
  getCrTypeLogo,
  getEnterCRButtons,
  useMoreActions,
} from 'components/CleanRooms/CrListTable';
import { stringifyDateRange } from 'utils/dateUtils';
import { useHistory } from 'react-router-dom';


export const createCrPartnerUrl = (crID) => crPartners.create(crID);

const getPartnerName = (details, history) => {
  const isAuthorized = PermissionService.isAuthorized(['CleanRoomsAdmin']);
  const isOwner = details.ownership === Ownership.OWNER.key;
  const crAccessibleToUser = details.userAccessible;
  const isCompleted = details.stage === CLEAN_ROOM_STAGES.COMPLETE.key;
  const isExpired = details.status === CLEAN_ROOM_STATUS.EXPIRED.key;

  // for aws clean room
  const isPending = details.stage === CLEAN_ROOM_STAGES.PENDING.key;
  const isReady = details.stage === CLEAN_ROOM_STAGES.READY.key;
  const isProvisioning = details.stage === CLEAN_ROOM_STAGES.PROVISIONING.key;
  const isAwsCleanRoom = details.type === CleanRoomTypes.AWS_CLEAN_ROOM;

  if (details.partners.length > 0) {
    return (
      <CardKeyValueRow
        title='Partners'
        value={details.partners.length}
        valueTooltip={details.partners.join(', ')}
      />
    );
  }
  if ((isProvisioning || isCompleted) && isAwsCleanRoom) {
    return (
      <CardKeyValueRow
        title='Partners'
        value='Add Partner'
        disabled
      />
    );
  }
  if ((isPending || isReady) && isAwsCleanRoom) {
    return (
      <Tooltip title={isExpired ? 'Clean Room Expired. Unable to add partner.' : ''} placement='right'>
        <span>
          <CardKeyValueRow
            title='Partners'
            value='Add Partner'
            onClick={() => history.push(createCrPartnerUrl(details.ID))}
            disabled={isExpired}
          />
        </span>
      </Tooltip>
    );
  }
  if (isAuthorized && isOwner && crAccessibleToUser && isCompleted) {
    return (
      <Tooltip title={isExpired ? 'Clean Room Expired. Unable to add partner.' : ''} placement='right'>
        <span>
          <CardKeyValueRow
            title='Partners'
            value='Add Partner'
            onClick={() => history.push(createCrPartnerUrl(details.ID))}
            disabled={isExpired}
          />
        </span>
      </Tooltip>
    );
  }

  return (
    <CardKeyValueRow
      title='Partners'
      value='Add Partner'
    />
  );
};

const CrCard = ({ details, row }) => {
  const localRow = details ?? row;
  const history = useHistory();
  const {
    getDetailsLink,
    handleOnSelectDeleteCr,
    handleOnSelectProvisionCr,
  } = React.useContext(CleanRoomsLinkContext);
  const getActionMenu = useMoreActions({
    handleOnDelete: handleOnSelectDeleteCr,
  });
  const crSecondaryActionList = getActionMenu(localRow);

  const isExpired = localRow.status === CLEAN_ROOM_STATUS.EXPIRED.key;

  const cardHeaderValue = isExpired ? CLEAN_ROOM_STATUS.EXPIRED.label
    : CLEAN_ROOM_STAGES[localRow.stage]?.label;

  const textColorValue = isExpired ? CLEAN_ROOM_STATUS.EXPIRED.statusTextColor
    : CLEAN_ROOM_STAGES[localRow.stage]?.stageTextColor;

  const headerColorValue = isExpired ? CLEAN_ROOM_STATUS.EXPIRED.color
    : CLEAN_ROOM_STAGES[localRow.stage]?.color;

  return (
    <BlankCard
      header={(
        <Grid container spacing={1} direction='row' justifyContent='space-between'>
          <Grid item xs={6}>
            <CardHeaderStatus
              value={cardHeaderValue}
              stageDetails={localRow.stage === CLEAN_ROOM_STAGES.FAILED.key && (
                <Tooltip title={localRow.errorMessage} placement='top'>
                  <ErrorOutlineIcon
                    fontSize='small'
                    sx={{ color: '#C62828', verticalAlign: 'middle', marginRight: '3px' }}
                  />
                </Tooltip>
              )}
              textColor={textColorValue}
            />
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'end' }}>
            <MoreButton items={crSecondaryActionList} variant='text' />
          </Grid>
        </Grid>
      )}
      headerColor={headerColorValue}
      actionButtons={
        getEnterCRButtons(
          localRow, getDetailsLink(localRow.ID, localRow.questionsCount),
          handleOnSelectProvisionCr,
        )
    }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardTitleRow
            Icon={getCrTypeLogo(localRow)}
            title={`${localRow.ownership} CR  •  ${localRow.displayID}`}
            subtitle={localRow.name}
          />
        </Grid>
        <Grid item xs={12}>
          <CardKeyValueRow title='Questions' value={localRow.questionsCount} />
          <Divider variant='middle' />
        </Grid>
        <Grid item xs={12}>
          <CardKeyValueRow title='Admin' value={localRow.cleanRoomAdmin?.email || ''} />
          <Divider variant='middle' />
        </Grid>
        <Grid item xs={12}>
          {getPartnerName(localRow, history)}
          <Divider variant='middle' />
        </Grid>
        <Grid item xs={12}>
          <CardKeyValueRow title='Date Range' value={stringifyDateRange(localRow)} />
        </Grid>
      </Grid>
    </BlankCard>
  );
};

export default CrCard;
