import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useUpsertCleanRoomParameters = (cleanRoomID) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    (data) => axiosInstance.put(cleanRoom(orgId)
      .upsertCrParameters(cleanRoomID), data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['CleanRoom', orgId, cleanRoomID]);
        dispatch(showAlert({
          message: 'Cleanroom parameter created/updated successfully!',
          type: 'success',
        }));
      },
      onError: () => {
        dispatch(showAlert({
          message: 'Error in creating/updating cleanroom parameter',
          type: 'error',
        }));
      },
    },
  );
};
