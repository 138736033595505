import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetCRFlowRunParametersAndMappings = ({ crFlowId, crId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    ['Fetch-CR-Flow-RunParameters-Mappings', orgId],
    async () => axiosInstance.get(flow(orgId).flowMacroMappings(crId, crFlowId)),
    {
      enabled: !!crFlowId,
      onError: (error) => dispatch(
        showAlert({
          message: error.response?.data?.message || 'Error fetching flow parameters',
          type: 'error',
        })),
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};
