import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { crTemplate } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useState } from 'react';


export const useAcceptCleanRoomContract = (crID) => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState();
  const { ID: orgID } = useSelector(activeOrgSelector);
  const [data, setData] = useState(null);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance
      .post(crTemplate(orgID).acceptCRContract(crID), payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
        setErrorMsg(err.response.data.message);
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  return {
    errorMsg,
    isLoading,
    isSuccess,
    isError,
    mutate,
    data,
  };
};
