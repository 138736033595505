import React, { useCallback, useMemo, useState } from 'react';
import TableSortIconLabel from 'components/Common/TableSortIconLabel';
import { LinkComponent } from 'BaseComponents';
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { internalAdmin } from 'utils/spaUrls';
import { rowsPerPageOptions } from 'utils/appConstants';
import { useFetchAccountUsage } from 'api/internalAdmin/snowflakeAccountUsage';


const AccountUsageTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('totalUsage');

  const { data, isLoading } = useFetchAccountUsage();

  const handleRequestSort = useCallback((property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }, [order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buildUrl = useCallback((accountName) => {
    const searchParams = new URLSearchParams();
    searchParams.set('accountName', accountName);
    searchParams.set('timeRange', 'last7Days');
    searchParams.set('groupBy', 'daily');
    return `${internalAdmin.application.snowflakeAccountUsageSingle}?${searchParams.toString()}`;
  }, []);

  const sortedData = useMemo(() => {
    if (data) {
      return data.sort((a, b) => {
        if (orderBy === 'totalUsage') {
          return order === 'asc' ? b.totalUsage - a.totalUsage : a.totalUsage - b.totalUsage;
        }
        return 0;
      });
    }
    return [];
  }, [data, order, orderBy]);

  const slicedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant='h4' mb={2}>
        Account Usage Statistics
      </Typography>
      {isLoading ? (
        <Skeleton variant='rectangular' width='100%' height={320} />
      ) : (
        <>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Account Name</TableCell>
                  <TableCell>
                    <TableSortIconLabel
                      active={orderBy}
                      direction={order}
                      field='totalUsage'
                      onClick={handleRequestSort}
                      title='Total Usage'
                    />
                  </TableCell>
                  <TableCell>Usage Current Month</TableCell>
                  <TableCell>Usage Last Month</TableCell>
                  <TableCell>Average Per Month</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  slicedData?.length > 0 ? (
                    slicedData.map((row) => (
                      <TableRow key={row.accountName}>
                        <TableCell>
                          <LinkComponent to={buildUrl(row.accountName)}>
                            <Typography variant='linkText' fontSize='0.875rem'>
                              {row.accountName}
                            </Typography>
                          </LinkComponent>
                        </TableCell>
                        <TableCell>
                          $
                          {Number(row.totalUsage).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          $
                          {Number(row.usageCurrentMonth).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          $
                          {Number(row.usageLastMonth).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          $
                          {Number(row.averagePerMonth).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align='center'>
                        <Typography>No data available</Typography>
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component='div'
            count={data?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </>
      )}
    </Paper>
  );
};

export default AccountUsageTable;
