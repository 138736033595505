import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchCrQuestionFederatedQuery = (
  cleanRoomID, cleanRoomQuestionID, enabled = true,
) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['crQuestionFederatedQuery', cleanRoomID, cleanRoomQuestionID],
    () => axiosInstance.get(cleanRoom(orgId).crFederatedQuery(cleanRoomID), {
      params: { cleanRoomQuestionID },
    }),
    {
      enabled,
      select: (res) => res?.data || {},
      onError: () => {
        dispatch(showAlert({
          message: 'Error fetching cleanroom question federated query',
          type: 'error',
        }));
      },
    },
  );
};


