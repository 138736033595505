import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetLinkedDatasetsWithVersion = (cleanRoomId, questionId) => {
  const dispatch = useDispatch();

  return useQuery(
    ['linkedDatasetsWithVersion', cleanRoomId, questionId],
    () => axiosInstance.get(cleanRoom().getLinkedDatasetsWithVersion(questionId, cleanRoomId)),
    {
      select: (res) => res?.data?.linkedDatasetsWithVersion ?? [],
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to fetch linked datasets with version',
          type: 'error',
        }));
      },
    },
  );
};
