import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import {
  setFlowDetailsSuccess,
  setFlowEdgesSuccess,
  setFlowNodesSuccess,
  submitFlowSuccess,
} from 'redux/actions/FlowAction';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';


// TODO: this function not being used in the code
const useFetchFlowEdges = ({ flowId, crId }) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  // eslint-disable-next-line no-unused-vars
  const placeHolderUrl = flow(orgId).edge(flowId, crId);
  const flowCache = useSelector(state => state.flow.flowsCache);
  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    'flow-edges',
    async () => {
      if (flowId) {
        // Fire the API call with flowId and crId
        const getFlow = flowCache.find(obj => obj.ID === flowId);
        const response = await new Promise(resolve => {
          setTimeout(() => {
            resolve(getFlow.edges);
          }, 10);
        });
        return response;
      }
      else {
        return [];
      }
    },
    {
      enabled: !!flowId, // Enable the query only if flowId is truthy
      onSuccess: (res) => {
        dispatch(setFlowEdgesSuccess(res));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};

// TODO: this function not being used in the code
const useFetchFlowNodes = ({ flowId, crId }) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const flowCache = useSelector(state => state.flow.flowsCache);

  // eslint-disable-next-line no-unused-vars
  const placeHolderUrl = flow(orgId).nodes(flowId, crId);
  const {
    isLoading, isError, data, isSuccess,
  } = useQuery(
    'flow-nodes',
    async () => {
      if (flowId) {
        // Fire the API call with flowId and crId
        const getFlow = flowCache.find(obj => obj.ID === flowId);
        const response = await new Promise(resolve => {
          setTimeout(() => {
            resolve(getFlow.nodes);
          }, 10);
        });
        return response;
      }
      else {
        // Use the mock data
        return [];
      }
    },
    {
      enabled: !!flowId, // Enable the query only if flowId is truthy
      onSuccess: (res) => {
        dispatch(setFlowNodesSuccess(res));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};

// TODO: this function not being used in the code
const useSubmitFlow = ({ crId }) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  // eslint-disable-next-line no-unused-vars
  const placeHolderUrl = flow(orgId).create(crId);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => {
      // Fire the API call with flowId and crId
      const response = await new Promise(resolve => {
        setTimeout(() => {
          resolve(payload);
        }, 10);
      });
      return response;
    },
    {
      onSuccess: (res) => {
        dispatch(submitFlowSuccess(res));
        dispatch(showAlert({ message: 'Flow has been submitted.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading, isError, isSuccess, mutate,
  };
};

const useUpdateFlow = (flowId) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    ['update-flow', orgId, flowId],
    async (payload) => axiosInstance.put(flow(orgId).update(flowId), payload),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Flow has been updated.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );
  return {
    isLoading, isError, isSuccess, mutate,
  };
};

const useSubmitFlowRun = ({ crId, flowId }) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    ['submit-flow-run', orgId, crId, flowId],
    async (payload) => axiosInstance.post(flow(orgId).createFlowRun(crId, flowId), payload),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Run has been submitted.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );
  return {
    isLoading, isError, isSuccess, mutate, reset,
  };
};

const useFetchFlowDetails = ({ crFlowId, crId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    ['Flow-details', crId, orgId],
    async () => axiosInstance.get(flow(orgId).details(crFlowId, crId)),
    {
      refetchOnWindowFocus: false,
      enabled: !!crFlowId, // Enable the query only if flowId is truthy
      onSuccess: (res) => {
        dispatch(setFlowDetailsSuccess(res.data.cleanRoomFlow));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};

// TODO: this function not being used in the code
const useFetchFlowRunParameters = ({ crFlowId, crId }) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    ['Flow-Run-Parameters'],
    async () => axiosInstance.get(flow(orgId).runParameters(crId, crFlowId)),
    {
      enabled: !!crFlowId, // Enable the query only if flowId is truthy
      onSuccess: () => dispatch(showAlert({ message: 'Flow Parameters fetched successfully .', type: 'success' })),
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};

export {
  useFetchFlowDetails,
  useFetchFlowEdges,
  useFetchFlowNodes,
  useFetchFlowRunParameters,
  useSubmitFlow,
  useSubmitFlowRun,
  useUpdateFlow,
};
