import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { crTemplate } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useDownloadCleanRoomContract = (contractName) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ crId }) => axiosInstance.get(crTemplate(orgId).downloadCrContract(crId), {
      responseType: 'blob',
    }),
    {
      onSuccess: (res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = contractName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
      },
    },
  );
  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};
