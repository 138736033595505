import PageHeader from 'components/Common/PageHeader';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import TableSortIconLabel from 'components/Common/TableSortIconLabel';
import moment from 'moment';
import {
  MenuItem, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TextField, Typography,
} from '@mui/material';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import {
  dateRangeOptions,
  groupByOptions,
} from 'pages/InternalAdmin/application/pages/SnowflakeAccountUsage/constant';
import { internalAdmin } from 'utils/spaUrls';
import { rowsPerPageOptions } from 'utils/appConstants';
import {
  useFetchAccountUsage,
  useFetchAccountUsageSingle,
} from 'api/internalAdmin/snowflakeAccountUsage';
import { useHistory } from 'react-router-dom';
import { useSearchParams } from 'hooks/useSearchParams';


const LoadingSkeleton = () => (
  <Stack spacing={2}>
    <Skeleton variant='rectangular' height={300} />
  </Stack>
);

const AccountUsageSingle = () => {
  const history = useHistory();
  const [queryParams, setQueryParams] = useSearchParams();

  const [selectedAccount, setSelectedAccount] = useState(queryParams.accountName);
  const [selectedTimeRange, setSelectedTimeRange] = useState(queryParams.timeRange || 'last7Days');
  const [selectedGroupBy, setSelectedGroupBy] = useState(queryParams.groupBy || 'daily');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('currencyUsed');

  const {
    data = [],
    isLoading,
  } = useFetchAccountUsageSingle({
    accountName: selectedAccount,
    timeRange: selectedTimeRange,
    groupBy: selectedGroupBy,
  });

  const {
    data: allAccountsData,
    isSuccess: isAllAccountsSuccess,
  } = useFetchAccountUsage();

  useEffect(() => {
    if (!selectedAccount) {
      history.push(internalAdmin.application.snowflakeAccountUsage);
    }
    else {
      setQueryParams({
        accountName: selectedAccount,
        timeRange: selectedTimeRange,
        groupBy: selectedGroupBy,
      });
    }
  }, [selectedAccount, selectedTimeRange, selectedGroupBy, setQueryParams, history]);

  const handleDateRangeChange = useCallback((event) => {
    const newTimeRange = event.target.value;
    setSelectedTimeRange(newTimeRange);

    if (newTimeRange === 'lastDay' || newTimeRange === 'last7Days') {
      setSelectedGroupBy('daily');
    }
    else if (newTimeRange === 'last12Months') {
      setSelectedGroupBy('monthly');
    }
  }, []);

  const handleGroupByChange = useCallback((event) => {
    setSelectedGroupBy(event.target.value);
  }, []);

  const handleAccountChange = useCallback((event) => {
    setSelectedAccount(event.target.value);
  }, []);

  const handleRequestSort = useCallback((property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }, [order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  }, []);

  const sortedData = useMemo(() => {
    if (data) {
      return data.sort((a, b) => {
        if (orderBy === 'currencyUsed') {
          return order === 'asc' ? b.currencyUsed - a.currencyUsed : a.currencyUsed - b.currencyUsed;
        }
        if (orderBy === 'dateTime') {
          return order === 'asc' ? moment(b.dateTime).diff(moment(a.dateTime)) : moment(a.dateTime).diff(moment(b.dateTime));
        }
        return 0;
      });
    }
    return [];
  }, [data, order, orderBy]);

  const allAccounts = useMemo(() => (
    isAllAccountsSuccess && allAccountsData
      ? allAccountsData.map((account) => account.accountName)
      : []
  ), [allAccountsData, isAllAccountsSuccess]);
  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <PageHeader
        title='Snowflake Account Usage Single'
        breadcrumbs={[crumbs.helium, crumbs.internalAdmin, crumbs.snowflakeAccountUsage]}
      />
      <Stack component={Paper} mb={2} p={2} direction='row' spacing={2}>
        <TextField
          id='account-name'
          select
          label='Select Account'
          variant='outlined'
          value={selectedAccount}
          onChange={handleAccountChange}
        >
          {
            allAccounts.map((account) => (
              <MenuItem value={account} key={account}>
                {account}
              </MenuItem>
            ))
          }
        </TextField>
        <TextField
          id='date-range'
          select
          label='Date Range'
          variant='outlined'
          value={selectedTimeRange}
          onChange={handleDateRangeChange}
        >
          {dateRangeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id='group-by'
          select
          label='Group By'
          variant='outlined'
          value={selectedGroupBy}
          onChange={handleGroupByChange}
        >
          {groupByOptions
            .filter((option) => {
              if (selectedTimeRange === 'lastDay' || selectedTimeRange === 'last7Days') {
                return option.value === 'daily';
              }
              if (selectedTimeRange === 'last12Months') {
                return option.value === 'monthly';
              }
              return true;
            })
            .map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      </Stack>
      {
        isLoading
          ? <LoadingSkeleton />
          : (
            <Paper>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortIconLabel
                          active={orderBy}
                          direction={order}
                          field='dateTime'
                          onClick={handleRequestSort}
                          title='Date Time'
                        />
                      </TableCell>
                      <TableCell>Account Name</TableCell>
                      <TableCell>
                        <TableSortIconLabel
                          active={orderBy}
                          direction={order}
                          field='currencyUsed'
                          onClick={handleRequestSort}
                          title='Cost'
                        />
                      </TableCell>
                      <TableCell>Region</TableCell>
                      <TableCell>Service Level</TableCell>
                      <TableCell>Usage Types</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((row) => (
                        <TableRow key={row.dateTime}>
                          <TableCell>{moment(row.dateTime).format('D MMM, YYYY')}</TableCell>
                          <TableCell>{row.accountName}</TableCell>
                          <TableCell>
                            $
                            {Number(row.currencyUsed).toFixed(2)}
                          </TableCell>
                          <TableCell>{row.region}</TableCell>
                          <TableCell>{row.serviceLevel}</TableCell>
                          <TableCell>
                            <Typography variant='inherit' maxWidth={250}>
                              {row.usageTypes}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align='center'>
                          <Typography>No data available</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )
      }
    </>
  );
};

export default AccountUsageSingle;
