import AccountUsageSingle
  from 'pages/InternalAdmin/application/pages/SnowflakeAccountUsage/AccountUsageSingle';
import CleanRoomQuestion
  from 'pages/InternalAdmin/application/pages/CleanRoomQuestion/CleanRoomQuestion';
import CleanRoomQuestions
  from 'pages/InternalAdmin/application/pages/CleanRoomQuestions/CleanRoomQuestions';
import CredentialSourceDetails
  from 'pages/InternalAdmin/application/pages/CredentialSourceDetails/CredentialSourceDetails';
import CredentialSources
  from 'pages/InternalAdmin/application/pages/CredentialSources/CredentialSources';
import PrivateRoute from 'components/Common/PrivateRoute';
import React from 'react';
import SnowflakeAccountUsage from 'pages/InternalAdmin/application/pages/SnowflakeAccountUsage';
import SnowflakeCliDashboard
  from 'pages/InternalAdmin/application/pages/SnowflakeCliDashboard/SnowflakeCliDashboard';
import { Switch } from 'react-router-dom';
import { internalAdmin } from 'utils/spaUrls';


const InternalAdminApplicationRouter = () => (
  <Switch>
    <PrivateRoute
      path={internalAdmin.application.cleanRoomQuestions}
      component={CleanRoomQuestions}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.cleanRoomQuestion()}
      component={CleanRoomQuestion}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.credentialSources}
      component={CredentialSources}
      exact
    />
    <PrivateRoute
      path={`${internalAdmin.application.credentialSourceDetail}/:id?`}
      component={CredentialSourceDetails}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.snowflakeCliDashboard}
      component={SnowflakeCliDashboard}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.snowflakeAccountUsage}
      component={SnowflakeAccountUsage}
      exact
    />
    <PrivateRoute
      path={internalAdmin.application.snowflakeAccountUsageSingle}
      component={AccountUsageSingle}
      exact
    />
  </Switch>
);

export default InternalAdminApplicationRouter;
