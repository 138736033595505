export const CR_CONFIG_TYPES = {
  CR_PARAMETER: 'crParameter',
  CR_DETAIL: 'crDetail',
  CR_PRIVACY_PARAMETER: 'crPrivacyParameter',
};

export const getCrConfigs = (cleanRoomDetails) => [
  {
    name: 'name',
    value: cleanRoomDetails.name,
    type: 'crDetail',
  },
  {
    name: 'description',
    value: cleanRoomDetails.description,
    type: 'crDetail',
  },
  ...(cleanRoomDetails.parameters?.map(param => ({ ...param, type: 'crParameter' })) || []),
  {
    name: 'partnerRiskAppetite',
    value: cleanRoomDetails.cleanRoomPrivacyParameter?.partnerRiskAppetite,
    type: 'crPrivacyParameter',
  },
  {
    name: 'partnerTrustLevel',
    value: cleanRoomDetails.cleanRoomPrivacyParameter?.partnerTrustLevel,
    type: 'crPrivacyParameter',
  },
  {
    name: 'cleanRoomPrivacyBudget',
    value: cleanRoomDetails.cleanRoomPrivacyParameter?.cleanRoomPrivacyBudget,
    type: 'crPrivacyParameter',
    dataType: 'number',
  },
  {
    name: 'delta',
    value: cleanRoomDetails.cleanRoomPrivacyParameter?.delta,
    type: 'crPrivacyParameter',
  },
  {
    name: 'maxContributions',
    value: cleanRoomDetails.cleanRoomPrivacyParameter?.maxContributions,
    type: 'crPrivacyParameter',
    dataType: 'number',
  },
  {
    name: 'maxQueryRuns',
    value: cleanRoomDetails.cleanRoomPrivacyParameter?.maxQueryRuns,
    type: 'crPrivacyParameter',
    dataType: 'number',
  },
  {
    name: 'timeHorizon',
    value: cleanRoomDetails.cleanRoomPrivacyParameter?.timeHorizon,
    type: 'crPrivacyParameter',
    dataType: 'number',
  },
];
