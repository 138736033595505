import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { crTemplate } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useCreateCleanRoomTemplate = () => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    async ({ payload }) => axiosInstance.post(crTemplate(orgId).createTemplate, payload),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Successfully created clean room template',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
      },
    },
  );
};
