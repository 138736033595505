import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { snowflakeAccountUsage } from 'utils/urlConstants';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchAccountSpendingStats = () => {
  const dispatch = useDispatch();

  return useQuery(
    ['accountSpendingStats'],
    () => axiosInstance.get(snowflakeAccountUsage.allAccountSpendStats),
    {
      retry: false,
      select: (res) => res?.data?.allAccountSpendStats ?? {},
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to fetch account spending stats',
          type: 'error',
        }));
      },
    },
  );
};
