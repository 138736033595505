import IconTooltip from 'components/Common/IconTooltip';
import React, { memo } from 'react';
import { Box } from '@mui/material';
import {
  CLEAN_ROOM_LOGOS,
} from 'components/CleanRooms/constants';


export const CleanRoomTypeCell = memo(({ row }) => {
  const title = CLEAN_ROOM_LOGOS[row.cleanRoomType.name]?.label;
  const src = CLEAN_ROOM_LOGOS[row.cleanRoomType.name]?.logoSrc;

  return (
    <Box display='flex' alignItems='center' gap={2}>
      <IconTooltip
        title={title}
        src={src}
        height={35}
        placement='top'
      />
    </Box>
  );
});

CleanRoomTypeCell.displayName = 'CleanRoomTypeCell';
