import AccountUsageTable from './AccountUsageTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';


const SnowflakeAccountUsage = () => (
  <>
    <PageHeader title='Snowflake Account Usage' breadcrumbs={[crumbs.helium, crumbs.internalAdmin]} />
    <AccountUsageTable />
  </>
);

export default SnowflakeAccountUsage;
