import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { Link } from 'react-router-dom';
import { MoreHoriz } from '@mui/icons-material';
import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledButton = styled(Button)`
  min-width: 25px;
  width: 25px;
  height: 25px;
`;

const MoreButton = ({ items, variant = 'outlined' }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (callback) => {
    setAnchorEl(null);
    callback();
  };

  if (items.length === 0) return null;

  return (
    <>
      <StyledButton onClick={handleClick} size='small' variant={variant} data-testid='more-button'>
        <MoreHoriz fontSize='small' />
      </StyledButton>
      <Popover
        data-testid='actions-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {items.map(item => {
          if (item.to) {
            return (
              <MenuItem
                component={Link}
                data-testid={item.testID}
                disabled={item.disabled}
                key={item.label}
                to={item.to}
              >
                {item.label}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              data-testid={item.testID}
              disabled={item.disabled}
              key={item.label}
              onClick={() => onClick(item.onClick)}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

export default MoreButton;
