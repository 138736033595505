import { CLEAN_ROOM } from 'redux/actions/Types';
import { FALLBACK_QUESTION_VERSION } from 'utils/constants/question.constant';
import { REPORT_LIMIT } from 'utils/appConstants';
import {
  cleanRoom,
  crPartners,
  question as questionAPI,
} from 'utils/urlConstants';


export const addQuestion = (orgId, crId, questionID) => ({
  type: CLEAN_ROOM.ADD_QUESTION,
  url: cleanRoom(orgId).addQuestion(crId),
  payload: { questionID },
});

export const addQuestionError = payload => ({
  type: CLEAN_ROOM.ADD_QUESTION_ERROR,
  payload,
});

export const addQuestionSuccess = payload => ({
  type: CLEAN_ROOM.ADD_QUESTION_SUCCESS,
  payload,
});

export const addCrUser = (orgId, crId, user) => ({
  type: CLEAN_ROOM.ADD_CR_USER,
  url: cleanRoom(orgId).addCrUser(crId),
  payload: user,
});

export const addCrUserError = payload => ({
  type: CLEAN_ROOM.ADD_CR_USER_ERROR,
  payload,
});

export const addCrUserOpenModal = () => ({ type: CLEAN_ROOM.ADD_CR_USER_OPEN_MODAL });

export const addCrUserSuccess = payload => ({
  type: CLEAN_ROOM.ADD_CR_USER_SUCCESS,
  payload,
});

export const createCr = () => ({ type: CLEAN_ROOM.CREATE_CR });

export const createDataset = (orgId, cleanRoomId, payload) => ({
  type: CLEAN_ROOM.CREATE_CR_DATASET,
  url: cleanRoom(orgId).createDataset(cleanRoomId),
  payload,
});

export const createDatasetError = payload => ({
  type: CLEAN_ROOM.CREATE_CR_DATASET_ERROR,
  payload,
});

export const createDatasetSuccess = payload => ({
  type: CLEAN_ROOM.CREATE_CR_DATASET_SUCCESS,
  payload,
});

export const createNewRun = (orgId, crId, crQuestionId, payload) => ({
  type: CLEAN_ROOM.CREATE_NEW_RUN,
  url: cleanRoom(orgId).createNewRun(crId, crQuestionId),
  payload,
});

export const createNewRunError = payload => ({
  type: CLEAN_ROOM.CREATE_NEW_RUN_ERROR,
  payload,
});

export const createNewRunReset = () => ({ type: CLEAN_ROOM.CREATE_NEW_RUN_RESET });

export const createNewRunSuccess = payload => ({
  type: CLEAN_ROOM.CREATE_NEW_RUN_SUCCESS,
  payload,
});

export const updateDataset = (orgId, cleanRoomId, datasetId, payload) => ({
  type: CLEAN_ROOM.UPDATE_CR_DATASET,
  url: cleanRoom(orgId).updateDataset(cleanRoomId, datasetId),
  payload,
});

export const updateDatasetError = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_DATASET_ERROR,
  payload,
});

export const updateDatasetSuccess = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_DATASET_SUCCESS,
  payload,
});

export const fetchDataset = (orgId, cleanRoomId, datasetId) => ({
  type: CLEAN_ROOM.FETCH_CR_DATASET,
  url: cleanRoom(orgId).getDataset(cleanRoomId, datasetId),
});

export const fetchDatasetError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_DATASET_ERROR,
  payload,
});

export const fetchDatasetSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_DATASET_SUCCESS,
  payload,
});

export const resetDatasetState = () => ({
  type: CLEAN_ROOM.RESET_CR_DATASET_STATE,
  payload: null,
});

export const fetchAndCacheDataset = (orgId, cleanRoomId, datasetId) => ({
  type: CLEAN_ROOM.FETCH_AND_CACHE_CR_DATASET,
  url: cleanRoom(orgId).getDataset(cleanRoomId, datasetId),
});

export const fetchAndCacheDatasetError = payload => ({
  type: CLEAN_ROOM.FETCH_AND_CACHE_CR_DATASET_ERROR,
  payload,
});

export const fetchAndCacheDatasetSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_AND_CACHE_CR_DATASET_SUCCESS,
  payload,
});

export const fetchCr = (orgId, id) => ({
  type: CLEAN_ROOM.FETCH_CR,
  url: cleanRoom(orgId).crDetail(id),
});

export const fetchCrError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_ERROR,
  payload,
});

export const fetchCrSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_SUCCESS,
  payload,
});

export const fetchCrOrgUserList = (orgUid, crId) => ({
  type: CLEAN_ROOM.FETCH_CR_ORG_USER_LIST,
  url: cleanRoom(orgUid).listCrOrgUsers(crId),
});

export const fetchCrUserOrgListError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_ORG_USER_LIST_ERROR,
  payload,
});

export const fetchCrUserOrgListSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_ORG_USER_LIST_SUCCESS,
  payload,
});

export const fetchCrStakeholderList = (orgUid, crId) => ({
  type: CLEAN_ROOM.FETCH_CR_STAKEHOLDER_LIST,
  url: cleanRoom(orgUid).listCrStakeholders(crId),
});

export const fetchCrList = (orgId, filters) => ({
  type: CLEAN_ROOM.FETCH_CR_LIST,
  url: cleanRoom(orgId).list,
  payload: filters,
});

export const fetchCrListError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_LIST_ERROR,
  payload,
});

export const fetchCrListSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_LIST_SUCCESS,
  payload,
});

export const fetchCrListFilters = orgId => ({
  type: CLEAN_ROOM.FETCH_CR_LIST_FILTERS,
  url: cleanRoom(orgId).listFilters,
});

export const fetchCrListFiltersError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_LIST_FILTERS_ERROR,
  payload,
});

export const fetchCrListFiltersSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_LIST_FILTERS_SUCCESS,
  payload,
});

export const fetchCrDatasetGroups = (orgId, crId) => ({
  type: CLEAN_ROOM.FETCH_CR_DATASET_GROUPS,
  url: cleanRoom(orgId).listDatasetGroupAll(crId),
});

export const fetchCrDatasetGroupsError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_DATASET_GROUPS_ERROR,
  payload,
});

export const fetchCrDatasetGroupsSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_DATASET_GROUPS_SUCCESS,
  payload,
});

export const fetchCrAvailableQuestions = (orgId, crId) => ({
  type: CLEAN_ROOM.FETCH_CR_ALL_QUESTIONS,
  url: cleanRoom(orgId).listAvailableQuestions(crId),
});

export const fetchCrAvailableQuestionsError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_ALL_QUESTIONS_ERROR,
  payload,
});

export const fetchCrAvailableQuestionsSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_ALL_QUESTIONS_SUCCESS,
  payload,
});

export const fetchCrCurrentQuestions = (orgId, crId) => ({
  type: CLEAN_ROOM.FETCH_CR_CURRENT_QUESTIONS,
  url: cleanRoom(orgId).listCurrentQuestions(crId),
});

export const fetchCrCurrentQuestionsError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_CURRENT_QUESTIONS_ERROR,
  payload,
});

export const fetchCrCurrentQuestionsSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_CURRENT_QUESTIONS_SUCCESS,
  payload,
});

export const fetchCrRunDetails = (orgId, crId, runId) => ({
  type: CLEAN_ROOM.FETCH_CR_RUN_DETAILS,
  url: cleanRoom(orgId).getCrRunDetail(crId, runId),
});

export const fetchCrRunDetailsSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_RUN_DETAILS_SUCCESS,
  payload,
});

export const fetchCrRunDetailsError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_RUN_DETAILS_ERROR,
  payload,
});

export const fetchCrUsers = (orgId, crId) => ({
  type: CLEAN_ROOM.FETCH_CR_USERS,
  url: cleanRoom(orgId).users(crId),
});

export const fetchCrUsersError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_USERS_ERROR,
  payload,
});

export const fetchCrUsersSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_USERS_SUCCESS,
  payload,
});

export const removeCrUser = (orgId, crId, userId) => ({
  type: CLEAN_ROOM.REMOVE_CR_USER,
  url: cleanRoom(orgId).removeUser(crId, userId),
});

export const removeCrUserError = payload => ({
  type: CLEAN_ROOM.REMOVE_CR_USER_ERROR,
  payload,
});

export const removeCrUserSuccess = payload => ({
  type: CLEAN_ROOM.REMOVE_CR_USER_SUCCESS,
  payload,
});

export const fetchQuestionDetails = (
  questionId,
  version = FALLBACK_QUESTION_VERSION,
  cleanroomID) => {
  const crID = cleanroomID ? `&cleanRoomID=${cleanroomID}` : '';
  return ({
    type: CLEAN_ROOM.FETCH_QUESTION_DETAILS,
    url: `${questionAPI()
      .detail(questionId)}?version=${version}${crID}`,
  });
};

export const fetchQuestionDetailsError = payload => ({
  type: CLEAN_ROOM.FETCH_QUESTION_DETAILS_ERROR,
  payload,
});

export const fetchQuestionDetailsSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_QUESTION_DETAILS_SUCCESS,
  payload,
});

export const fetchCrQuestion = (orgId, crId, questionId) => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION,
  url: cleanRoom(orgId).getCleanRoomQuestion(crId, questionId),
});

export const fetchCrQuestionV2 = (orgId, crId, cleanRoomQuestionID) => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION_V2,
  url: cleanRoom(orgId).getCleanRoomQuestionV2(crId, cleanRoomQuestionID),
});

export const fetchCrQuestionError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION_ERROR,
  payload,
});

export const fetchCrQuestionSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION_SUCCESS,
  payload,
});

export const updateCrQuestionDetails = (orgId, crId, questionId, payload) => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_DETAILS,
  url: cleanRoom(orgId).updateCrQuestionDetails(crId, questionId),
  payload,
});

export const updateCrQuestionDetailsV2 = (orgId, crId, cleanRoomQuestionID, payload) => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_DETAILS_V2,
  url: cleanRoom(orgId).updateCrQuestionDetailsV2(crId, cleanRoomQuestionID),
  payload,
});

export const updateCrQuestionDescriptionError = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_DESCRIPTION_ERROR,
  payload,
});

export const updateCrQuestionDescriptionSuccess = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_DESCRIPTION_SUCCESS,
  payload,
});

export const fetchCrQuestionDatasets = (orgId, crId, crQuestionId) => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION_DATASETS,
  url: cleanRoom(orgId).questionDatasets(crId, crQuestionId),
});

export const fetchCrQuestionDatasetsError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION_DATASETS_ERROR,
  payload,
});

export const fetchCrQuestionDatasetsSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION_DATASETS_SUCCESS,
  payload,
});

export const resetCrQuestionDatasetsState = () => ({
  type: CLEAN_ROOM.RESET_CR_QUESTION_DATASETS_STATE,
});

export const fetchCrQuestionDataOptions = (orgId, crId, questionId,
  isPartnerAssignedDatasets = false) => (
  {
    type: CLEAN_ROOM.FETCH_CR_QUESTION_DATA_OPTIONS,
    url: cleanRoom(orgId).questionDataOptions(crId, questionId, isPartnerAssignedDatasets),
  }
);

export const fetchCrQuestionDataOptionsError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION_DATA_OPTIONS_ERROR,
  payload,
});

export const fetchCrQuestionDataOptionsSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_QUESTION_DATA_OPTIONS_SUCCESS,
  payload,
});

export const fetchCrReportFilters = (orgId, crId, crQuestionId, crRunId) => ({
  type: CLEAN_ROOM.FETCH_CR_REPORT_FILTERS,
  runId: crRunId,
  url: cleanRoom(orgId).reportFilters(crId, crQuestionId, crRunId),
});

export const fetchCrReportFiltersError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_REPORT_FILTERS_ERROR,
  payload,
});

export const fetchCrReportFiltersSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_REPORT_FILTERS_SUCCESS,
  payload,
});

export const fetchQuestionRun = (orgId, crId, crQuestionId, crRunId) => ({
  type: CLEAN_ROOM.FETCH_QUESTION_RUN,
  url: cleanRoom(orgId).getQuestionRun(crId, crQuestionId, crRunId),
});

export const fetchQuestionRunError = payload => ({
  type: CLEAN_ROOM.FETCH_QUESTION_RUN_ERROR,
  payload,
});

export const fetchQuestionRunSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_QUESTION_RUN_SUCCESS,
  payload,
});

export const fetchScheduledRuns = (orgId, cleanRoomId, questionId) => ({
  type: CLEAN_ROOM.FETCH_SCHEDULED_RUNS,
  url: cleanRoom(orgId).getScheduledRuns(cleanRoomId, questionId),
});

export const fetchScheduledRunsError = payload => ({
  type: CLEAN_ROOM.FETCH_SCHEDULED_RUNS_ERROR,
  payload,
});

export const fetchScheduledRunsSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_SCHEDULED_RUNS_SUCCESS,
  payload,
});

export const fetchScheduledRun = (orgId, cleanRoomId, scheduleId) => ({
  type: CLEAN_ROOM.FETCH_SCHEDULED_RUN,
  url: cleanRoom(orgId).getScheduledRun(cleanRoomId, scheduleId),
});

export const fetchScheduledRunError = payload => ({
  type: CLEAN_ROOM.FETCH_SCHEDULED_RUN_ERROR,
  payload,
});

export const fetchScheduledRunSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_SCHEDULED_RUN_SUCCESS,
  payload,
});

export const getCrRunReport = (orgId, crId, crQuestionId, crRunId, filters) => ({
  type: CLEAN_ROOM.GET_CR_RUN_REPORT,
  url: cleanRoom(orgId).getRunReport(crId, crQuestionId, crRunId),
  payload: { filters, limit: REPORT_LIMIT },
});

export const getCrRunReportError = payload => ({
  type: CLEAN_ROOM.GET_CR_RUN_REPORT_ERROR,
  payload,
});

export const getCrRunReportSuccess = payload => ({
  type: CLEAN_ROOM.GET_CR_RUN_REPORT_SUCCESS,
  payload,
});

export const getCrRunTableData = (
  orgId, crId, crQuestionId, crRunId, isAggregate, filters, limit, offset, sortBy,
) => ({
  type: CLEAN_ROOM.GET_CR_RUN_TABLE_REPORT,
  url: cleanRoom(orgId).getRunTableReport(crId, crQuestionId, crRunId),
  payload: {
    filters, isAggregate, limit, offset, sortBy,
  },
});

export const getCrRunTableReportError = payload => ({
  type: CLEAN_ROOM.GET_CR_RUN_TABLE_REPORT_ERROR,
  payload,
});

export const getCrRunTableReportSuccess = payload => ({
  type: CLEAN_ROOM.GET_CR_RUN_TABLE_REPORT_SUCCESS,
  payload,
});


export const getCrRunFiles = (orgId, crId, crQuestionId, crRunId) => ({
  type: CLEAN_ROOM.GET_CR_RUN_FILES,
  url: cleanRoom(orgId).getOutputFiles(crId, crQuestionId, crRunId),
});

export const getCrRunFilesError = payload => ({
  type: CLEAN_ROOM.GET_CR_RUN_FILES_ERROR,
  payload,
});

export const getCrRunFilesSuccess = payload => ({
  type: CLEAN_ROOM.GET_CR_RUN_FILES_SUCCESS,
  payload,
});

export const getCrRunFilePreview = (orgId, crId, crQuestionId, crRunId, fileName) => ({
  type: CLEAN_ROOM.GET_CR_RUN_FILE_PREVIEW,
  url: cleanRoom(orgId).getFilePreview(crId, crQuestionId, crRunId, fileName),
});

export const getCrRunFilePreviewError = payload => ({
  type: CLEAN_ROOM.GET_CR_RUN_FILE_PREVIEW_ERROR,
  payload,
});

export const getCrRunFilePreviewSuccess = payload => ({
  type: CLEAN_ROOM.GET_CR_RUN_FILE_PREVIEW_SUCCESS,
  payload,
});

export const getCrRuns = (orgId, crId, crQuestionId, filter) => ({
  type: CLEAN_ROOM.GET_CR_RUNS,
  url: cleanRoom(orgId).getRuns(crId, crQuestionId),
  payload: filter,
});

export const getDPBudgetAndQueries = (trustLevel, riskTolerance, orgId) => ({
  type: CLEAN_ROOM.GET_DP_BUDGET_AND_QUERIES,
  url: cleanRoom(orgId).getPrivacyBudgetAndQueries(String(trustLevel), String(riskTolerance)),
});


export const getDPBudgetAndQueriesSuccess = payload => ({
  type: CLEAN_ROOM.GET_DP_BUDGET_AND_QUERIES_SUCCESS,
  payload,
});


export const getDPBudgetAndQueriesError = payload => ({
  type: CLEAN_ROOM.GET_DP_BUDGET_AND_QUERIES_ERROR,
  payload,
});

export const getCrRunsError = payload => ({
  type: CLEAN_ROOM.GET_CR_RUNS_ERROR,
  payload,
});

export const getCrRunsSuccess = payload => ({
  type: CLEAN_ROOM.GET_CR_RUNS_SUCCESS,
  payload,
});

export const fetchCrRunsFilters = (orgId, crId, crQuestionId) => ({
  type: CLEAN_ROOM.FETCH_CR_RUNS_FILTERS,
  url: cleanRoom(orgId).fetchRunsFilters(crId, crQuestionId),
});

export const fetchCrRunsFiltersError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_RUNS_FILTERS_ERROR,
  payload,
});

export const fetchCrRunsFiltersSuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_RUNS_FILTERS_SUCCESS,
  payload,
});

export const deleteCrQuestion = (orgId, crId, crQuestionId) => ({
  type: CLEAN_ROOM.DELETE_CR_QUESTION,
  url: cleanRoom(orgId).deleteQuestion(crId, crQuestionId),
});

export const deleteCrQuestionError = payload => ({
  type: CLEAN_ROOM.DELETE_CR_QUESTION_ERROR,
  payload,
});

export const deleteCrQuestionSuccess = payload => ({
  type: CLEAN_ROOM.DELETE_CR_QUESTION_SUCCESS,
  payload,
});

export const deleteCrQuestionRun = (orgId, crId, crQuestionId, runId) => ({
  type: CLEAN_ROOM.DELETE_CR_QUESTION_RUN,
  url: cleanRoom(orgId).deleteQuestionRun(crId, crQuestionId, runId),
});

export const deleteCrQuestionRunError = payload => ({
  type: CLEAN_ROOM.DELETE_CR_QUESTION_RUN_ERROR,
  payload,
});

export const deleteCrQuestionRunSuccess = payload => ({
  type: CLEAN_ROOM.DELETE_CR_QUESTION_RUN_SUCCESS,
  payload,
});

export const saveCr = (orgId, payload) => ({
  type: CLEAN_ROOM.SAVE_CR,
  url: (payload.cleanRoom.ID) ? `${cleanRoom(orgId).create}/${payload.cleanRoom.ID}` : cleanRoom(orgId).create,
  payload,
});

export const saveCrError = payload => ({
  type: CLEAN_ROOM.SAVE_CR_ERROR,
  payload,
});

export const saveCrSuccess = payload => ({
  type: CLEAN_ROOM.SAVE_CR_SUCCESS,
  payload,
});

export const resetCr = () => ({
  type: CLEAN_ROOM.RESET_CR,
});

export const updateCrStatus = (orgId, crId, status, meta) => ({
  meta,
  payload: { status },
  type: CLEAN_ROOM.UPDATE_CR_STATUS,
  url: cleanRoom(orgId).updateCrStatus(crId),
});

export const updateCrStatusError = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_STATUS_ERROR,
  payload,
});

export const updateCrUser = (orgId, crId, userId, user) => ({
  payload: user,
  type: CLEAN_ROOM.UPDATE_CR_USER,
  url: cleanRoom(orgId).updateCrUser(crId, userId),
});

export const updateCrUserError = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_USER_ERROR,
  payload,
});

export const updateCrUserSuccess = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_USER_SUCCESS,
  payload,
});

export const updateCrQuestionStatus = (orgId, crId, crQuestionId, question) => ({
  payload: question,
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_STATUS,
  url: cleanRoom(orgId).updateCrQuestionStatus(crId, crQuestionId),
});

export const updateCrQuestionStatusError = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_STATUS_ERROR,
  payload,
});

export const updateCrQuestionStatusSuccess = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_STATUS_SUCCESS,
  payload,
});

export const updateCrQuestionDatasets = (orgId, crId, crQuestionId, isFinalStep, payload) => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_DATASETS,
  url: cleanRoom(orgId).questionDatasets(crId, crQuestionId),
  payload,
  isFinalStep,
});

export const updateCrQuestionDatasetsError = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_DATASETS_ERROR,
  payload,
});

export const updateCrQuestionDatasetsSuccess = payload => ({
  type: CLEAN_ROOM.UPDATE_CR_QUESTION_DATASETS_SUCCESS,
  payload,
});

export const fetchCrSummary = orgId => ({
  type: CLEAN_ROOM.FETCH_CR_SUMMARY,
  url: cleanRoom(orgId).summary,
});

export const fetchCrSummarySuccess = payload => ({
  type: CLEAN_ROOM.FETCH_CR_SUMMARY_SUCCESS,
  payload,
});

export const fetchCrSummaryError = payload => ({
  type: CLEAN_ROOM.FETCH_CR_SUMMARY_ERROR,
  payload,
});

export const resetReport = () => ({ type: CLEAN_ROOM.RESET_REPORTS });

export const verifyPartnerKey = (orgId, partnerKey) => ({
  type: CLEAN_ROOM.VERIFY_PARTNER_KEY,
  url: crPartners(orgId).verifyPartnerKey,
  payload: { ApiKey: partnerKey },
});

export const verifyPartnerKeyError = payload => ({
  type: CLEAN_ROOM.VERIFY_PARTNER_KEY_ERROR,
  payload,
});

export const verifyPartnerKeySuccess = payload => ({
  type: CLEAN_ROOM.VERIFY_PARTNER_KEY_SUCCESS,
  payload,
});

export const verifyPartnerEmail = (orgId, partnerOrganizationID, email) => ({
  type: CLEAN_ROOM.VERIFY_PARTNER_EMAIL,
  url: crPartners(orgId).verifyPartnerEmail,
  payload: { partnerOrganizationID, email },
});

export const verifyPartnerEmailError = payload => ({
  type: CLEAN_ROOM.VERIFY_PARTNER_EMAIL_ERROR,
  payload,
});

export const verifyPartnerEmailSuccess = payload => ({
  type: CLEAN_ROOM.VERIFY_PARTNER_EMAIL_SUCCESS,
  payload,
});
