import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { snowflakeAccountUsage } from 'utils/urlConstants';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchAccountUsageSingle = ({ accountName, timeRange, groupBy }) => {
  const dispatch = useDispatch();

  return useQuery(
    ['accountUsageSingle', accountName, timeRange, groupBy],
    () => axiosInstance.get(snowflakeAccountUsage.singleAccountUsageSummary, {
      params: {
        accountName,
        timeRange,
        groupBy,
      },
    }),
    {
      retry: false,
      enabled: !!accountName && !!timeRange && !!groupBy,
      select: (res) => res?.data?.singleAccountUsage ?? [],
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to fetch account usage',
          type: 'error',
        }));
      },
    },
  );
};
