import React from 'react';
import { axiosInstance } from 'utils/http';
import { dataCredential } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchOrgCredentials = (orgId, filter) => {
  const dispatch = useDispatch();

  const [data, setData] = React.useState([]);

  const {
    isLoading, isError, isSuccess,
  } = useQuery(
    ['orgCredentials', orgId],
    async () => axiosInstance
      .get(dataCredential(orgId).listOrgCredentials(filter)),
    {
      enabled: !!orgId,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};
