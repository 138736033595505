import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { memo } from 'react';
import { Box, Tooltip } from '@mui/material';
import { habuColors } from 'Theme';


export const TooltipIcon = memo(({
  color = habuColors.signal.alert[80], message = '', onClick = () => {}, children,
}) => (
  <Box
    onClick={onClick}
    sx={{ display: 'inline-flex', verticalAlign: 'middle', cursor: 'pointer' }}
  >
    <Tooltip
      title={message}
      placement='top'
      data-testid='CrListTable-tooltip'
    >
      <>
        <ErrorOutlineIcon
          fontSize='small'
          sx={{ color, marginRight: '3px' }}
        />
        {children}
      </>
    </Tooltip>
  </Box>
));
