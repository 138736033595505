import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { snowflakeAccountUsage } from 'utils/urlConstants';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchAccountUsage = ({ startDate, endDate } = {}) => {
  const dispatch = useDispatch();

  return useQuery(
    ['accountUsage'],
    () => axiosInstance.get(snowflakeAccountUsage.allAccountSpendSummary, {
      params: {
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      },
    }),
    {
      retry: false,
      select: (res) => res?.data?.allAccountSpendSummary ?? [],
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to fetch account usage',
          type: 'error',
        }));
      },
    },
  );
};
