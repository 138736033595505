import React, { createContext, useEffect, useState } from 'react';
import { cleanRoom } from 'utils/spaUrls';
import { useClearErrorMessage } from 'api/cleanRoomsTemplate';
import { useDeleteCleanRoom } from 'api/cleanrooms';
import { useDeleteCleanRoomTemplate } from 'api/cleanrooms/useDeleteCleanRoomTemplate';
import { useProvisionCleanRoom } from 'hooks/cleanRoomAPIs';
import { useSelector } from 'react-redux';


const cloneOptions = [
  'Generate Clean Room',
  'Add to existing Clean Rooms',
  'Add to other Organizations',
];

export const CleanRoomsLinkContext = createContext({});

export const CleanRoomsLinkContextProvider = ({ dataContextRef, children }) => {
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [openProvisionModal, setOpenProvisionModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTemplateDeleteModal, setOpenTemplateDeleteModal] = useState(false);
  const [openCloneToExistModal, setOpenCloneToExistModal] = useState(false);
  const [selectedCleanRoom, setSelectedCleanRoom] = useState(null);
  const [selectedCRMap, setSelectedCRMap] = useState({});
  const [openCloneToOrgModal, setOpenCloneToOrgModal] = useState(false);
  const [openClearCloneErrorMessageModal, setOpenClearCloneErrorMessageModal] = useState(false);

  const getDetailsLink = (crId, questionCount) => (questionCount > 0 ? cleanRoom.question(crId)
    : cleanRoom.manageDatasets(crId));

  const {
    mutateAsync: provisionCleanRoom,
    isLoading: isProvisionLoading,
    isSuccess: isProvisionSuccess,
  } = useProvisionCleanRoom();

  const {
    isLoading: isDeleteLoading, isSuccess: isDeleteSuccess, mutateAsync: deleteCrAsync,
  } = useDeleteCleanRoom();

  const { mutateAsync: clearErrorMeesageAsync } = useClearErrorMessage();

  const {
    isLoading: isDeleteCrTemplateLoading,
    isSuccess: isDeleteCrTemplateSuccess,
    mutate: deleteCrTemplate,
  } = useDeleteCleanRoomTemplate();

  const handleSelectCloneCr = selectedCr => {
    setOpenCloneModal(true);
    setSelectedCleanRoom(selectedCr);
  };

  const handleSelectCloneToExist = selectedCr => {
    setOpenCloneToExistModal(true);
    setSelectedCleanRoom(selectedCr);
  };

  const handleOnSelectCrCloneToOrg = (selectedCr) => {
    setOpenCloneToOrgModal(true);
    setSelectedCleanRoom(selectedCr);
  };

  const handleOnClickCloneOptions = (option, selectedCr) => {
    switch (option) {
      case cloneOptions[0]:
        // Generate Clean Room
        handleSelectCloneCr(selectedCr);
        break;
      case cloneOptions[1]:
        // Add to existing Clean Rooms
        handleSelectCloneToExist(selectedCr);
        break;
      case cloneOptions[2]:
        // Add to other Organizations
        handleOnSelectCrCloneToOrg(selectedCr);
        break;
      default:
        // details
        break;
    }
  };

  const handleOnSelectDeleteCr = (selectedCr) => {
    setOpenDeleteModal(true);
    setSelectedCleanRoom(selectedCr);
  };

  const handleOnSelectProvisionCr = (selectedCr) => {
    setOpenProvisionModal(true);
    setSelectedCleanRoom(selectedCr);
  };

  const handleSelectDeleteCrTemplate = selectedCr => {
    setOpenTemplateDeleteModal(true);
    setSelectedCleanRoom(selectedCr);
  };

  const handleSelectClearCloneCrTemplateErrorMessage = selectedCr => {
    setOpenClearCloneErrorMessageModal(true);
    setSelectedCleanRoom(selectedCr);
  };

  const handleDeleteConfirm = async () => {
    await deleteCrAsync({ orgId, crID: selectedCleanRoom.ID });
    dataContextRef.current.refreshListData();
  };

  const handleDeleteCrTemplateConfirm = () => {
    deleteCrTemplate({ orgId, crID: selectedCleanRoom.ID });
    dataContextRef.current.refreshListData();
  };

  const handleProvisionConfirm = async () => {
    await provisionCleanRoom({
      crID: selectedCleanRoom.ID,
    });
    dataContextRef.current.refreshListData();
  };

  const handleOnClaerCloneErrorMessage = async (crId) => {
    await clearErrorMeesageAsync(crId);
    dataContextRef.current.refreshListData();
    setOpenClearCloneErrorMessageModal(false);
  };

  useEffect(() => {
    if (isDeleteSuccess) { setOpenDeleteModal(false); }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isProvisionSuccess) {
      setOpenProvisionModal(false);
    }
  }, [isProvisionSuccess]);

  useEffect(() => {
    if (isDeleteCrTemplateSuccess) { setOpenTemplateDeleteModal(false); }
  }, [isDeleteCrTemplateSuccess]);

  return (
    <CleanRoomsLinkContext.Provider value={{
      selectedCleanRoom,
      setSelectedCleanRoom,
      cloneOptions,
      handleOnClickCloneOptions,
      handleSelectDeleteCrTemplate,
      handleOnSelectDeleteCr,
      handleOnSelectProvisionCr,
      handleDeleteConfirm,
      handleDeleteCrTemplateConfirm,
      handleOnClaerCloneErrorMessage,
      selectedCRMap,
      setSelectedCRMap,
      getDetailsLink,
      isDeleteLoading,
      isDeleteCrTemplateLoading,
      setOpenCloneModal,
      setOpenDeleteModal,
      setOpenTemplateDeleteModal,
      openCloneModal,
      openDeleteModal,
      openTemplateDeleteModal,
      openProvisionModal,
      setOpenProvisionModal,
      openCloneToExistModal,
      setOpenCloneToExistModal,
      openCloneToOrgModal,
      setOpenCloneToOrgModal,
      openClearCloneErrorMessageModal,
      setOpenClearCloneErrorMessageModal,
      handleSelectClearCloneCrTemplateErrorMessage,
      handleProvisionConfirm,
      isProvisionLoading,
      dataContextRef,
    }}

    >
      {children}
    </CleanRoomsLinkContext.Provider>
  );
};
