import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';


export const useDeleteCleanRoomParameter = (crId) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    isLoading, isError, isSuccess, mutate, mutateAsync,
  } = useMutation(({ crID, paramName }) => axiosInstance
    .delete(cleanRoom(orgId).deleteCrParameter(crID, paramName)),
  {
    onSuccess: () => {
      queryClient.invalidateQueries(['CleanRoom', orgId, crId]);
      dispatch(showAlert({
        message: 'Clean room parameter deleted.',
        type: 'success',
      }));
    },
    onError: (err) => {
      dispatch(showAlert({
        message: err.response.data.message,
        type: 'error',
      }));
      setErrorMsg(err.response.data.message);
    },
  },
  );

  return {
    errorMsg,
    isLoading,
    isSuccess,
    isError,
    mutate,
    mutateAsync,
  };
};
