import PermissionService from 'components/Common/PermissionService';
import React, { memo } from 'react';
import {
  CLEAN_ROOM_STAGES,
  CLEAN_ROOM_STATUS,
  CleanRoomTypes,
  Ownership,
} from 'components/CleanRooms/constants';
import {
  DisabledLink,
  StyledLink,
} from 'components/CleanRooms/CrManagement/CleanRoomSettings/AddPartnerLink';
import { Tooltip } from '@mui/material';
import { createCrPartnerUrl } from 'components/CleanRooms/CrCard';


export const PartnerNameCell = memo(({ row }) => {
  const isAuthorized = PermissionService.isAuthorized(['CleanRoomsAdmin']);
  const isOwner = row.ownership === Ownership.OWNER.key;
  const crAccessibleToUser = row.userAccessible;
  const isCompleted = row.stage === CLEAN_ROOM_STAGES.COMPLETE.key;
  const isExpired = row.status === CLEAN_ROOM_STATUS.EXPIRED.key;

  // for aws clean room
  const isPending = row.stage === CLEAN_ROOM_STAGES.PENDING.key;
  const isReady = row.stage === CLEAN_ROOM_STAGES.READY.key;
  const isProvisioning = row.stage === CLEAN_ROOM_STAGES.PROVISIONING.key;
  const isAwsCleanRoom = row.type === CleanRoomTypes.AWS_CLEAN_ROOM;

  if (row.partners.length > 0) {
    return row.partners.length;
  }

  const link = (
    <StyledLink to={createCrPartnerUrl(row.ID)}>
      Add Partner
    </StyledLink>
  );

  const disabledLink = (
    <DisabledLink>
      Add Partner
    </DisabledLink>
  );

  const renderLink = () => {
    if (isOwner && isCompleted && isAuthorized && crAccessibleToUser) {
      return isExpired ? disabledLink : link;
    }
    if ((isProvisioning || isCompleted) && isAwsCleanRoom) {
      return isExpired ? disabledLink : link;
    }
    if ((isPending || isReady) && isAwsCleanRoom) {
      return isExpired ? disabledLink : link;
    }
    return null;
  };

  return (
    <Tooltip title={isExpired ? 'Clean Room Expired. Unable to add partner.' : ''}>
      <span>{renderLink()}</span>
    </Tooltip>
  );
});

PartnerNameCell.displayName = 'PartnerNameCell';
