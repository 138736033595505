import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FlowRunForm from 'pages/Flow/FlowRun/FlowRunForm';
import FlowRunMacroTable from 'pages/Flow/FlowRun/FlowRunMacroTable';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import PermissionService from 'components/Common/PermissionService';
import React, { useContext, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DEFAULT_PAUSE_LEVEL_VALUE } from 'redux/reducer/FlowReducer/constants';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { FlowRunContext } from 'pages/Flow/FlowRun/FlowRunProvider';
import {
  FormHelperText,
  MenuItem,
  Select,
} from '@mui/material';
import {
  HButton, HDialogActions, HDialogTitle, HSpinner,
} from 'BaseComponents';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { productPerm } from 'utils/appConstants';
import { useGetCRFlowRunParametersAndMappings } from 'api/flows/useGetCRFlowRunParametersAndMappings';
import { useGetDPAccountantView } from 'api/cleanrooms/useGetDPAccountantView';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSubmitFlowRun } from 'hooks/Flow/flowAPI';


const FlowRunDialog = () => {
  const { crId } = useParams();
  const { dataContextRef } = useContext(FlowContext);
  const { isOpen, closeModal, modalDetails } = React.useContext(FlowRunContext);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [runDetails, setRunDetails] = React.useState({ name: '', flowParameters: [] });
  const [transformedData, setTransformedData] = React.useState([]);
  const [macroData, setMacroData] = React.useState([]);
  const [pauseLevelID, setPauseLevelID] = React.useState(DEFAULT_PAUSE_LEVEL_VALUE);
  const hasFlowReusableRunsPermission = PermissionService.isProductEnabled([
    productPerm.FlowReusableRuns,
  ]);

  const { ID: orgID } = useSelector(activeOrgSelector);
  const crDetail = useSelector((state) => state.cleanRooms.detail);

  const isDifferentialPrivacyEnabled = PermissionService.isProductEnabled([
    productPerm.DifferentialPrivacy,
  ]);

  const handleModalClose = () => {
    closeModal();
    setMacroData([]);
    setTransformedData([]);
    setIsFormValid(false);
    setRunDetails({ name: '', flowParameters: [] });
    setPauseLevelID(DEFAULT_PAUSE_LEVEL_VALUE);
  };

  const {
    isError: isFlowRunParamMappingError,
    isSuccess: isFlowRunParamMappingSuccess,
    isLoading: isFlowRunParamMappingLoading,
    data: flowRunParametersMapping,
  } = useGetCRFlowRunParametersAndMappings({ crFlowId: modalDetails?.ID, crId });

  const levels = flowRunParametersMapping?.data?.levels || [];

  const { refetch: refetchBudgetAccountant } = useGetDPAccountantView(orgID, crId, false);

  const {
    mutate,
    isSuccess: flowRunAPISuccess,
    reset: resetFlowRunMutation,
    isLoading: loading,
  } = useSubmitFlowRun({ flowId: modalDetails?.ID, crId });

  const isSubmitDisabled = useMemo(
    () => loading || !isFormValid || isFlowRunParamMappingError,
    [loading, isFormValid, isFlowRunParamMappingError]);

  const processFlowRunParameters = (flowRunParameters) => {
    const macros = [];
    const runParametersWithoutMacros = [];
    const macroIds = new Set();

    flowRunParameters.forEach((flowRunParam) => {
      const nodeRunParamsWithoutMacros = {
        nodeID: flowRunParam.nodeID,
        nodeQuestionName: flowRunParam.nodeQuestionName,
        nodeRunParameters: [],
      };

      flowRunParam.nodeRunParameters.forEach((nodeRunParam) => {
        // Create a new object to avoid reassigning the parameter
        const newNodeRunParam = { ...nodeRunParam, value: '' };

        // Special check for START_DATE and END_DATE
        if (!newNodeRunParam.ID) {
          if (newNodeRunParam.name === 'START_DATE') {
            newNodeRunParam.ID = 'START_DATE';
          }
          else if (newNodeRunParam.name === 'END_DATE') {
            newNodeRunParam.ID = 'END_DATE';
          }
        }

        if (newNodeRunParam.macro) {
          if (!macroIds.has(newNodeRunParam.macro.ID)) {
            const macroWithMapping = {
              ...newNodeRunParam.macro,
              runParamMapping: [],
            };
            macros.push(macroWithMapping);
            macroIds.add(newNodeRunParam.macro.ID);
          }
          const macro = macros.find((m) => m.ID === newNodeRunParam.macro.ID);
          macro.runParamMapping.push({
            nodeID: flowRunParam.nodeID,
            runParamID: newNodeRunParam.ID,
            runParamName: newNodeRunParam.name,
          });
        }
        else {
          nodeRunParamsWithoutMacros.nodeRunParameters.push(newNodeRunParam);
        }
      });

      // Avoid Empty Entries: Only add nodeRunParameters if it has at least one entry
      if (nodeRunParamsWithoutMacros.nodeRunParameters.length > 0) {
        runParametersWithoutMacros.push(nodeRunParamsWithoutMacros);
      }
    });

    return { macros, runParametersWithoutMacros };
  };

  React.useEffect(() => {
    if (isFlowRunParamMappingSuccess && flowRunParametersMapping) {
      const res = processFlowRunParameters(flowRunParametersMapping?.data?.flowRunParameters);
      setMacroData(res.macros);
      setTransformedData(res.runParametersWithoutMacros);
    }
  }, [flowRunParametersMapping, isFlowRunParamMappingSuccess]);

  const areAllMacrosValid = (m) => m.every((macro) => macro.value && macro.value.trim() !== '');

  const isAllTransformedDataValid = (d) => d.every((item) => item.nodeRunParameters.every((param) => param.value && param.value.trim() !== ''),
  );

  React.useEffect(() => {
    let isValid = runDetails.name !== '' && isAllTransformedDataValid(transformedData);
    isValid = isValid && areAllMacrosValid(macroData);
    setIsFormValid(isValid);
  }, [macroData, transformedData, runDetails]);

  if (modalDetails === null) return null;

  const handleSubmit = () => {
    // Transform transformedData into a map for quick lookup by nodeID
    const transformedDataMap = new Map(
      transformedData.map((item) => [
        item.nodeID,
        Object.fromEntries(
          item.nodeRunParameters.map((param) => [
            param.name,
            {
              value: param.value,
              parameterType: param.parameterType,
            },
          ]),
        ),
      ]),
    );

    // Transform macroData into a map for quick lookup by nodeID
    const macroDataMap = new Map();
    macroData.forEach((macro) => {
      macro.runParamMapping.forEach((mapping) => {
        if (!macroDataMap.has(mapping.nodeID)) {
          macroDataMap.set(mapping.nodeID, {});
        }
        macroDataMap.get(mapping.nodeID)[mapping.runParamName] = {
          value: macro.value,
        };
      });
    });

    // Merge the data from both maps
    const mergedData = Array.from(
      new Set([...transformedDataMap.keys(), ...macroDataMap.keys()]),
    ).map((nodeID) => ({
      nodeID,
      parameters: { ...transformedDataMap.get(nodeID), ...macroDataMap.get(nodeID) },
    }));

    const payload = { cleanRoomFlowRun: { ...runDetails, flowParameters: mergedData } };

    if (hasFlowReusableRunsPermission && pauseLevelID) {
      payload.cleanRoomFlowRun.pauseLevelID = `Level-${pauseLevelID}`;
    }

    mutate(payload);
  };

  const handleNodeRunParamFormFieldChange = ({ e, nodeID, nodeParam }) => {
    const { value } = e.target;
    const { ID: selectedParamID, name: selectedParamName } = nodeParam;
    const updatedData = transformedData.map((item) => {
      if (item.nodeID === nodeID) {
        const updatedNodeRunParameters = item.nodeRunParameters.map((param) => {
          const enabledStartDate = selectedParamName === 'START_DATE' && param.name === 'START_DATE';
          const enabledEndDate = selectedParamName === 'END_DATE' && param.name === 'END_DATE';
          const enabledDateParameters = selectedParamName === 'START_DATE' || selectedParamName === 'END_DATE';

          if (param.ID === selectedParamID) {
            if (enabledStartDate && enabledDateParameters) {
              return { ...param, value };
            }

            if (enabledEndDate && enabledDateParameters) {
              return { ...param, value };
            }

            if (!enabledEndDate && !enabledStartDate && !enabledDateParameters) {
              return {
                ...param,
                value,
              };
            }
          }

          return param;
        });

        return {
          ...item,
          nodeRunParameters: updatedNodeRunParameters,
        };
      }
      return item;
    });

    setTransformedData(updatedData);
  };
  const updateMacroValue = (macros, macroID, newValue) => macros.map((macro) => {
    if (macro.ID === macroID) {
      return { ...macro, value: newValue };
    }
    return macro;
  });

  const handleUpdateMacroValue = (macroID, newValue) => {
    setMacroData((prevMacros) => updateMacroValue(prevMacros, macroID, newValue));
  };

  if (flowRunAPISuccess) {
    resetFlowRunMutation();
    setRunDetails({ name: '', flowParameters: [] });
    dataContextRef.current.refreshListData();
    if (isDifferentialPrivacyEnabled && crDetail?.cleanRoomPrivate) {
      refetchBudgetAccountant();
    }
    closeModal();
  }

  const showPauseLevelSelector = hasFlowReusableRunsPermission
    && !!levels.length
    && levels.length > 1;

  return (
    <Dialog onClose={handleModalClose} open={isOpen} fullWidth maxWidth='md'>
      <HDialogTitle onClose={handleModalClose}>
        <Typography variant='h3'>New Flow Run</Typography>
      </HDialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={showPauseLevelSelector ? 6 : 12}>
              <TextField
                data-testid='report-flow-text-field'
                fullWidth
                label='Run Name'
                margin='normal'
                onChange={(e) => setRunDetails({
                  ...runDetails,
                  name: e.target.value,
                })}
                required
                type='text'
                value={runDetails.name || ''}
                variant='outlined'
              />
            </Grid>
            {showPauseLevelSelector && (
            <Grid item xs={6} alignItems='center'>
              <FormControl style={{ width: '100%', marginTop: '32px' }}>
                <Select
                  label={DEFAULT_PAUSE_LEVEL_VALUE}
                  value={pauseLevelID}
                  onChange={(e) => setPauseLevelID(e.target.value)}
                  fullWidth
                >
                  <MenuItem
                    key={DEFAULT_PAUSE_LEVEL_VALUE}
                    value={DEFAULT_PAUSE_LEVEL_VALUE}
                  >
                    {DEFAULT_PAUSE_LEVEL_VALUE}
                  </MenuItem>
                  {levels.map((level) => (
                    <MenuItem key={`level-${level}`} value={level} disabled={level === 0}>
                      Level
                      {' '}
                      {level}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  All remaining levels will run if no choice is made.
                </FormHelperText>
              </FormControl>
            </Grid>
            )}
            {
              !!macroData.length
              && (
              <Grid container spacing={1}>
                <Typography variant='h3Branding' marginTop={2} marginLeft={2}>
                  Macros
                </Typography>
                <FlowRunMacroTable
                  error={isFlowRunParamMappingError}
                  loading={isFlowRunParamMappingLoading}
                  listOfFlowMacros={macroData}
                  onUpdateMacroValue={handleUpdateMacroValue}
                />
              </Grid>
              )
            }
            <Grid container spacing={1}>
              <Typography variant='h3Branding' marginTop={2} marginLeft={2}>
                Parameters
              </Typography>
              <FlowRunForm
                flowRunTimeParameters={transformedData}
                loading={isFlowRunParamMappingLoading}
                runDetails={runDetails}
                updateNodeRunParam={handleNodeRunParamFormFieldChange}
                updateRunDetails={setRunDetails}
              />
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <HDialogActions>
        <HButton variant='outlined' onClick={handleModalClose}>
          Cancel
        </HButton>
        <HButton
          variant='contained'
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          endIcon={loading ? <HSpinner isButton /> : null}
        >
          Create Run
        </HButton>
      </HDialogActions>
    </Dialog>
  );
};

export default FlowRunDialog;
