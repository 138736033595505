import React from 'react';
import {
  GenerateAPIKeyModalLayout,
} from 'pages/APIKeyManagement/GenerateAPIKeyModalLayout/GenerateAPIKeyModalLayout';
import {
  GenerateAPIKeyProvider,
} from 'pages/APIKeyManagement/GenerateAPIKeyProvider';


export const GenerateAPIKeyModal = ({
  showAPISecretModal,
  setShowAPISecretModal,
  rotatedApiKey,
  isRotated,
  rotatingSecret,
  resetRotatedApiKey,
}) => (
  <GenerateAPIKeyProvider
    openModal={showAPISecretModal}
    setOpenModal={setShowAPISecretModal}
    rotatedApiKey={rotatedApiKey}
    rotatingSecret={rotatingSecret}
    isRotated={isRotated}
    resetRotatedApiKey={resetRotatedApiKey}
  >
    <GenerateAPIKeyModalLayout />
  </GenerateAPIKeyProvider>
);
