import React from 'react';
import { Tooltip } from '@mui/material';


const IconTooltip = ({
  title = '', src, alt, placement = 'top-start', height = 25,
}) => (
  <Tooltip title={title} placement={placement}>
    <img
      src={src}
      alt={alt || title}
      height={height}
    />
  </Tooltip>
);

export default IconTooltip;
