import React from 'react';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { crRunReport } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchCrQuestionRuns = (crId, questionId, filter) => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();

  const [data, setData] = React.useState(null);

  const {
    isLoading, isError, isSuccess,
  } = useQuery(
    ['CleanRoomQuestionRuns', crId, orgId],
    async () => axiosInstance
      .get(crRunReport(orgId).questionRuns(crId, questionId, { status: filter })),
    {
      enabled: !!questionId,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};
