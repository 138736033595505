import React from 'react';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { crTemplate } from 'utils/urlConstants';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';


export const useFetchCleanRoomContract = (crId) => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const [data, setData] = React.useState(null);
  const {
    isLoading, isError, isSuccess,
  } = useQuery(
    ['CleanRoomContract', crId, orgId],
    async () => axiosInstance.get(crTemplate(orgId).getCrContract(crId)),
    {
      enabled: !!crId,
      onSuccess: (res) => setData(res.data),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};
