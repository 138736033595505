import { axiosInstance } from 'utils/http';
import { crTemplate } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useState } from 'react';


export const useDeleteCleanRoomTemplate = () => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ crID }) => axiosInstance.delete(crTemplate(orgId).deleteCrTemplate(crID)),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Clean room template deleted.',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
        setErrorMsg(err.response.data.message);
      },
    },
  );

  return {
    errorMsg,
    isLoading,
    isSuccess,
    isError,
    mutate,
  };
};
