import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useState } from 'react';


export const useCloneCleanRoom = () => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const accountId = useSelector(state => state.session.user.primaryAccount.ID);

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ crID, payload }) => axiosInstance
      .post(cleanRoom(orgId).clone(accountId, crID), payload),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Generating Clean room. After a few minutes, it will appear in My Clean Rooms',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
        setErrorMsg(err.response.data.message);
      },
    },
  );
  return {
    errorMsg,
    isLoading,
    isSuccess,
    isError,
    mutate,
  };
};
