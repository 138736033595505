import React from 'react';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';


export const useFetchCrStakeholders = () => {
  const { crId } = useParams();
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const [data, setData] = React.useState([]);
  const { isLoading, isError, isSuccess } = useQuery(
    ['fetchCrStakeholders'],
    async () => axiosInstance.get(cleanRoom(orgId).listCrStakeholders(crId)),
    {
      onSuccess: (res) => setData(res.data.cleanRoomStakeholders),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};
