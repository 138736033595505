import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useDeleteCrQuestionRun = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    isLoading, isError, isSuccess, mutate, error, reset,
  } = useMutation(
    async ({ crID, crQID, runID }) => axiosInstance.delete(
      cleanRoom(orgId).deleteQuestionRun(crID, crQID, runID)),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: 'Clean room question run deleted.',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading, isError, isSuccess, mutate, error, reset,
  };
};
