import PermissionService from 'components/Common/PermissionService';
import React, { memo, useContext } from 'react';
import {
  CLEAN_ROOM_STAGES, CLEAN_ROOM_STATUS,
} from 'components/CleanRooms/constants';
import { CleanRoomsLinkContext } from 'pages/CleanRooms/CleanRoomsLinkContextProvider';
import { TooltipIcon } from 'components/CleanRooms/CrListLayout/componentsCell/StageCell/TooltipIcon';
import { habuColors } from 'Theme';
import { productPerm } from 'utils/appConstants';


export const StageCell = memo(({ row }) => {
  const isAuthorized = PermissionService.isAuthorized([productPerm.crAdmin]);
  const {
    handleSelectClearCloneCrTemplateErrorMessage,
  } = useContext(CleanRoomsLinkContext);

  const stageLabel = row.status === CLEAN_ROOM_STATUS.EXPIRED.key
    ? CLEAN_ROOM_STATUS.EXPIRED.label
    : CLEAN_ROOM_STAGES[row.stage]?.label || CLEAN_ROOM_STAGES.UNKNOWN.label;

  const stageColor = row.status === CLEAN_ROOM_STATUS.EXPIRED.key
    ? CLEAN_ROOM_STATUS.EXPIRED.statusTextColor
    : CLEAN_ROOM_STAGES[row.stage]?.stageTextColor;

  if (row.stage === CLEAN_ROOM_STAGES.FAILED.key) {
    return (
      <TooltipIcon
        color={habuColors.signal.alert[80]}
        message={row.errorMessage}
      >
        <span style={{ color: stageColor }}>{stageLabel}</span>
      </TooltipIcon>
    );
  }

  if (isAuthorized && row.stage === CLEAN_ROOM_STAGES.COMPLETE.key && row.errorMessage) {
    return (
      <TooltipIcon
        message={row.errorMessage}
        onClick={() => handleSelectClearCloneCrTemplateErrorMessage(row)}
        color={habuColors.signal.warning[40]}
      >
        <span style={{ color: stageColor }}>{stageLabel}</span>
      </TooltipIcon>
    );
  }

  return (
    <span style={{ color: stageColor }}>{stageLabel}</span>
  );
});

StageCell.displayName = 'StageCell';
