import Paper from '@mui/material/Paper';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { API_KEY_TYPE_TO_DISPLAY_NAME } from 'pages/APIKeyManagement/constants';
import { ButtonGroup } from '@mui/material';
import { HButton } from 'BaseComponents';
import { UI_DISPLAY_DATE_TIME_FORMAT } from 'utils/appConstants';


export const GenerateAPIKeyListLayout = ({
  apiKeys,
  onDelete,
  onRotateSecret,
}) => (
  <Paper sx={{ padding: '2rem' }} elevation={0}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Client Id</TableCell>
          <TableCell>Partner</TableCell>
          <TableCell>Key Type</TableCell>
          <TableCell>Date Created</TableCell>
          <TableCell>Date Updated</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {apiKeys.map((row) => (
          <TableRow key={row.ID}>
            <TableCell><b>{row.ClientID}</b></TableCell>
            <TableCell><b>{row.PartnerDisplayName}</b></TableCell>
            <TableCell><b>{API_KEY_TYPE_TO_DISPLAY_NAME[row.KeyType]}</b></TableCell>
            <TableCell>{moment(row.CreatedAt).format(UI_DISPLAY_DATE_TIME_FORMAT)}</TableCell>
            <TableCell>{moment(row.UpdatedAt).format(UI_DISPLAY_DATE_TIME_FORMAT)}</TableCell>
            <TableCell>
              <ButtonGroup>
                <HButton
                  color='inherit'
                  size='small'
                  variant='contained'
                  onClick={() => onRotateSecret(row.ClientID)}
                >
                  Rotate API Secret
                </HButton>
                <HButton
                  color='primary'
                  size='small'
                  variant='contained'
                  onClick={() => onDelete(row.ClientID)}
                >
                  Delete
                </HButton>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Paper>
);
