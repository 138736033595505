export const dateRangeOptions = [
  {
    value: 'lastDay',
    label: 'Last Day',
  },
  {
    value: 'last7Days',
    label: 'Last 7 Days',
  },
  {
    value: 'last28Days',
    label: 'Last 28 Days',
  },
  {
    value: 'last3Months',
    label: 'Last 3 Months',
  },
  {
    value: 'last6Months',
    label: 'Last 6 Months',
  },
  {
    value: 'last12Months',
    label: 'Last 12 Months',
  },
];

export const groupByOptions = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
];
