import Box from '@mui/material/Box';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import NoData from 'components/Common/NoData';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import { Card } from '@mui/material';
import { GenerateAPIKeyListLayout } from 'components/APIKeys/GenerateAPIKeyListLayout';
import { GenerateAPIKeyModal } from 'pages/APIKeyManagement/GenerateAPIKeyModal';
import { ListControls } from 'components/Common/ListControls';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import {
  useDeleteAPIKey,
  useFetchAPIKey,
  useRotateAPISecret,
} from 'hooks/APIKeyManagement/keyManagementAPIs';
import { useSelector } from 'react-redux';
import {
  userPermissions, userTypes,
} from 'utils/appConstants';


const APIKeyManagement = () => {
  const [clientID, updateClientID] = React.useState('');
  const permissionList = [userTypes.superuser, userTypes.accountAdmin, userPermissions.orgAdmin];
  const [showAPISecretModal, setShowAPISecretModal] = React.useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = React.useState(false);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    data: apiKeys,
  } = useFetchAPIKey();

  const {
    data: rotatedApiKey,
    mutateAsync: rotateSecret,
    isLoading: rotatingSecret,
    isSuccess: isRotated,
    reset: resetRotatedApiKey,
  } = useRotateAPISecret(orgId);

  const {
    isLoading: deletingKey,
    mutate: deleteAPIKey,
  } = useDeleteAPIKey(orgId);

  const isEmpty = !Array.isArray(apiKeys) || !apiKeys.length;

  const onGenerateKey = () => {
    setShowAPISecretModal(true);
  };

  const onDelete = (clientId) => {
    updateClientID(clientId);
    setShowDeleteConfirmationModal(true);
  };

  const onRotateSecret = (clientId) => {
    const payload = {
      clientID: clientId,
    };
    setShowAPISecretModal(true);
    rotateSecret({ payload });
  };

  const renderDeleteConfirmationDialog = () => (
    <ConfirmationDialog
      message='This action cannot be undone!'
      open={showDeleteConfirmationModal}
      loading={deletingKey}
      onCancel={() => {
        setShowDeleteConfirmationModal(false);
        updateClientID('');
      }}
      onConfirm={() => {
        deleteAPIKey(clientID);
        setShowDeleteConfirmationModal(false);
      }}
      title='Delete API Key?'
      confirmActionLabel='Delete'
    />
  );

  return (
    <>
      <PageHeader
        title='Manage API Key'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Card>
        <ListControls
          createButton={{
            text: 'Generate New Key',
            onClick: onGenerateKey,
          }}
          createPermissions={permissionList}
        />
      </Card>
      <Box mt={3}>
        {!isEmpty ? (
          <GenerateAPIKeyListLayout
            apiKeys={apiKeys}
            onDelete={onDelete}
            onRotateSecret={onRotateSecret}
          />
        ) : <NoData />}
      </Box>
      <GenerateAPIKeyModal
        showAPISecretModal={showAPISecretModal}
        setShowAPISecretModal={setShowAPISecretModal}
        rotatedApiKey={rotatedApiKey}
        rotatingSecret={rotatingSecret}
        isRotated={isRotated}
        resetRotatedApiKey={resetRotatedApiKey}
      />
      {renderDeleteConfirmationDialog()}
    </>
  );
};

export default APIKeyManagement;
