import IconTooltip from 'components/Common/IconTooltip';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import {
  CLEAN_ROOM_LOGOS,
  CLEAN_ROOM_STAGES,
  CleanRoomTypes,
  Ownership,
} from 'components/CleanRooms/constants';
import { HButton } from 'BaseComponents';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import { cleanRoom } from 'utils/spaUrls';
import {
  productPerm, userTypes,
} from 'utils/appConstants';


export const getEnterCRButtons = (row, detailsLink, handleOnSelectProvisionCr) => {
  const crAccessibleToUser = row.userAccessible;
  const isOwner = row.ownership === Ownership.OWNER.key;

  const isAwsCleanRoomAndPending = row.type === CleanRoomTypes.AWS_CLEAN_ROOM
    && row.stage === CLEAN_ROOM_STAGES.PENDING.key;

  const isAwsCleanRoomReady = row.type === CleanRoomTypes.AWS_CLEAN_ROOM
    && row.stage === CLEAN_ROOM_STAGES.READY.key;

  const isAwsCleanRoomFailed = row.type === CleanRoomTypes.AWS_CLEAN_ROOM
    && row.stage === CLEAN_ROOM_STAGES.FAILED.key;

  const isAwsCleanRoomAndProvisioning = row.type
    === CleanRoomTypes.AWS_CLEAN_ROOM && row.stage === CLEAN_ROOM_STAGES.PROVISIONING.key;


  if (!crAccessibleToUser && row.stage !== CLEAN_ROOM_STAGES.COMPLETE.key
    && !isAwsCleanRoomAndPending) {
    return null;
  }

  const buttons = [];

  // For AWS_CLEAN_ROOM and stage FAILED and the user is an OWNER
  if (isAwsCleanRoomFailed && isOwner) {
    buttons.push(
      <HButton
        data-testid='clean-room-provision-button'
        size='small'
        variant='contained'
        color='primary'
        onClick={() => handleOnSelectProvisionCr(row)}
      >
        Reprovision
      </HButton>,
    );
  }

  // For AWS_CLEAN_ROOM and stage READY and the user is an OWNER
  if (isAwsCleanRoomReady && isOwner) {
    buttons.push(
      <HButton
        data-testid='clean-room-provision-button'
        size='small'
        variant='contained'
        color='primary'
        onClick={() => handleOnSelectProvisionCr(row)}
      >
        Provision
      </HButton>,
    );
  }

  // Condition for displaying the ENTER button for both OWNER and PARTNER
  if (isAwsCleanRoomReady || isAwsCleanRoomAndPending || isAwsCleanRoomAndProvisioning
    || (crAccessibleToUser && row.stage === CLEAN_ROOM_STAGES.COMPLETE.key)) {
    buttons.push(
      <HButton
        data-testid='clean-room-details-button'
        component={Link}
        to={detailsLink}
        size='small'
        variant='contained'
        color='secondary'
      >
        Enter
      </HButton>,
    );
  }

  return buttons.length > 0 ? (
    <Stack direction='row' spacing={1}>
      {buttons}
    </Stack>
  ) : null;
};

export const useMoreActions = (action) => {
  const isSuperUser = PermissionService.isAuthorized([userTypes.superuser]);
  const isAuthorized = PermissionService.isAuthorized([productPerm.crAdmin]);

  return (row) => {
    const isOwner = row.ownership === Ownership.OWNER.key;
    const isAwsCleanRoom = row.type === CleanRoomTypes.AWS_CLEAN_ROOM;

    const isAwsCleanRoomAndPending = row.type === CleanRoomTypes.AWS_CLEAN_ROOM
      && row.stage === CLEAN_ROOM_STAGES.PENDING.key;

    const isAwsCleanRoomAndProvisioning = row.type
      === CleanRoomTypes.AWS_CLEAN_ROOM && row.stage === CLEAN_ROOM_STAGES.PROVISIONING.key;

    const isEditableType = () => {
      if (isAwsCleanRoom) {
        return isAwsCleanRoomAndPending;
      }
      return true;
    };

    const secondActionButtonList = [];

    const editButton = {
      label: 'Edit',
      testID: 'clean-room-edit-button',
      link: true,
      to: row.isTemplate ? cleanRoom.templateWizard(row.ID) : `${cleanRoom.wizard(row.ID)}`,
      disabled: (row.stage && row.stage !== CLEAN_ROOM_STAGES.COMPLETE.key)
          && !isAwsCleanRoomAndPending && !isAwsCleanRoomAndProvisioning,
    };

    const deleteButton = {
      label: 'Delete',
      testID: 'clean-room-delete-button',
      onClick: () => action?.handleOnDelete(row),
      action: () => action?.handleOnDelete(row),
    };

    if (isSuperUser
      || (isSuperUser && isOwner)
      || (isAuthorized && isOwner && row.userAccessible && isEditableType())) {
      secondActionButtonList.push(editButton);
    }

    if (action?.handleOnDelete
      && ((isSuperUser && isOwner)
        || (isAuthorized && isOwner && row.userAccessible))) {
      secondActionButtonList.push(deleteButton);
    }

    return secondActionButtonList;
  };
};

export const getCrTypeLogo = (crDetails) => (
  <IconTooltip
    title={CLEAN_ROOM_LOGOS[crDetails.cleanRoomType?.name]?.label}
    src={CLEAN_ROOM_LOGOS[crDetails.cleanRoomType?.name]?.logoSrc}
    height={35}
  />
);
