import React, { useContext } from 'react';
import { API_KEY_TYPES } from 'pages/APIKeyManagement/constants';
import {
  FormControl, Grid, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import { GenerateAPIKeyContext } from 'pages/APIKeyManagement/GenerateAPIKeyProvider';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { useSelector } from 'react-redux';


export const GenerateAPIKeyDetailsLayout = () => {
  const {
    apiPartners,
    selectedPartner,
    setSelectedPartner,
    selectedType,
    setSelectedType,
  } = useContext(GenerateAPIKeyContext);

  const { name: orgName } = useSelector(activeOrgSelector);

  return (
    <>
      <Typography variant='body2' marginBottom={4}>
        API keys allow access to the LiveRamp Clean Room API for this org.
        Refer to the API Help Doc for the complete authentication guide.
      </Typography>
      <Grid item xs={6}>
        <FormControl sx={{ my: 1, minWidth: '100%' }} required>
          <InputLabel id='api-key-type'>Select Types</InputLabel>
          <Select
            labelId='api-key-type-select'
            label='Select API Key Type'
            onChange={e => setSelectedType(e.target.value)}
            value={selectedType}
            fullWidth
            required
          >

            {API_KEY_TYPES(orgName).map((type) => (
              <MenuItem key={type.Name} value={type.Name}>
                {type.DisplayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {selectedType === 'PARTNER' && (
      <Grid item xs={6}>
        <FormControl sx={{ my: 1, minWidth: '100%' }} required>
          <InputLabel id='partner'>Select Partners</InputLabel>
          <Select
            labelId='partner-select'
            label='Select Partner'
            onChange={e => setSelectedPartner(e.target.value)}
            value={selectedPartner}
            required
            fullWidth
          >

            {apiPartners.map((apiPartner) => (
              <MenuItem key={apiPartner.Name} value={apiPartner.ID}>
                {apiPartner.DisplayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      )}
    </>
  );
};
