import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { snakeToDisplayString } from 'utils/jsUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useCreateCrQuestionConfigParams = (cleanRoomID, parameterName = '') => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    (data) => axiosInstance.post(cleanRoom(orgId)
      .crQuestionConfigParameter(cleanRoomID), data),
    {
      onSuccess: () => {
        dispatch(showAlert({
          message: `Cleanroom question config parameter parameter ${snakeToDisplayString(parameterName)} created successfully!`,
          type: 'success',
        }));
      },
      onError: () => {
        dispatch(showAlert({
          message: `Error creating cleanroom question config parameter: ${snakeToDisplayString(parameterName)}`,
          type: 'error',
        }));
      },
    },
  );
};
