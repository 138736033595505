import React, {
  createContext, useState,
} from 'react';
import { OWNER, PARTNER } from 'pages/APIKeyManagement/constants';
import { showAlert } from 'redux/actions/AlertActions';
import {
  useCreateAPIKey,
  useFetchAPIKey,
  useFetchAPIPartners,
} from 'hooks/APIKeyManagement/keyManagementAPIs';
import { useDispatch } from 'react-redux';


export const GenerateAPIKeyContext = createContext({});

export const GenerateAPIKeyProvider = ({
  openModal,
  setOpenModal,
  children,
  rotatedApiKey,
  isRotated,
  rotatingSecret,
  resetRotatedApiKey,
}) => {
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedType, setSelectedType] = useState(OWNER);
  const [steps, setSteps] = useState(0);
  const [createdAPIKey, setCreatedAPIKey] = useState({});
  const dispatch = useDispatch();

  const {
    data: apiPartners,
  } = useFetchAPIPartners();

  const {
    data: apiKeys,
    refetch: refetchKeys,
  } = useFetchAPIKey();

  const {
    data: generatedApiKey,
    isLoading: generatingKey,
    mutateAsync: createAPIKey,
    isSuccess: isGeneratedApiKeySuccess,
    reset: resetGeneratedApiKey,
  } = useCreateAPIKey();

  const clearModalState = () => {
    if (!generatingKey) {
      setOpenModal(false);
      setSteps(0);
      setSelectedType(OWNER);
      setSelectedPartner('');
      refetchKeys();
      resetGeneratedApiKey();
      resetRotatedApiKey();
      setCreatedAPIKey({});
    }
  };

  const handleCancel = () => {
    clearModalState();
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(createdAPIKey?.ClientSecret);
    clearModalState();
    dispatch(showAlert({ message: 'API Secret Copied!', type: 'success' }));
  };

  const handleRotatedCopy = async () => {
    await navigator.clipboard.writeText(rotatedApiKey?.ClientSecret);
    clearModalState();
    dispatch(showAlert({ message: 'Rotated API Secret Copied!', type: 'success' }));
  };

  const handleSubmit = async () => {
    if (steps === 0) {
      if (selectedType === '') {
        dispatch(showAlert({ message: 'Please select api key type', type: 'error' }));
      }
      else if (selectedType === PARTNER && selectedPartner === '') {
        dispatch(showAlert({ message: 'Please select partner to share api key', type: 'error' }));
      }
      else {
        const payload = {
          partnerID: selectedPartner,
          type: selectedType,
        };

        await createAPIKey({ payload });
        setSteps(1);
      }
    }
    else if (steps === 1) {
      handleCopy();
    }
  };

  React.useEffect(() => {
    if (isGeneratedApiKeySuccess || rotatingSecret) {
      setSteps(1);
    }
    if (isRotated) {
      setCreatedAPIKey(rotatedApiKey);
    }
    else if (isGeneratedApiKeySuccess) {
      setCreatedAPIKey(generatedApiKey);
    }
  }, [rotatedApiKey, generatedApiKey, isGeneratedApiKeySuccess,
    isRotated, rotatingSecret, generatingKey]);

  const GenerateAPIKeyContextValue = {
    selectedPartner,
    setSelectedPartner,
    selectedType,
    setSelectedType,
    openModal,
    setOpenModal,
    apiKeys,
    apiPartners,
    handleSubmit,
    handleCancel,
    generatingKey,
    setSteps,
    steps,
    handleCopy,
    createdAPIKey,
    handleRotatedCopy,
    rotatingSecret,
    isRotated,
    isGeneratedApiKeySuccess,
  };

  return (
    <GenerateAPIKeyContext.Provider value={GenerateAPIKeyContextValue}>
      {children}
    </GenerateAPIKeyContext.Provider>
  );
};
