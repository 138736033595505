import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useFetchCleanRoomTemplateQuestionsIdList = (crTID) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    () => axiosInstance.get(cleanRoom(orgId).cloneableQuestionsIdList(crTID)),
    {
      onSuccess: (res) => res.data.questionIds,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};
