export const API_KEY_TYPE_TO_DISPLAY_NAME = {
  PARTNER: 'Partner Key',
  OWNER: 'Client Key',
  '': 'Client Key',
};

export const OWNER = 'OWNER';

export const PARTNER = 'PARTNER';

export const API_KEY_TYPES = (orgName) => [{
  Name: OWNER,
  DisplayName: `${orgName} Key`,
}, {
  Name: PARTNER,
  DisplayName: 'Partner API Key',
}];

