import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { snakeToDisplayString } from 'utils/jsUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetCrQuestionConfigParam = (cleanRoomID, cleanRoomQuestionID, paramName = '', enabled) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['getCrQuestionConfigParam', cleanRoomID, cleanRoomQuestionID, paramName],
    () => axiosInstance.get(cleanRoom(orgId).crQuestionConfigParameter(cleanRoomID), {
      params: {
        cleanRoomQuestionID,
        paramName,
      },
    }),
    {
      enabled,
      select: res => res.data,
      onError: () => {
        dispatch(showAlert({
          message: `Error fetching cleanroom question config parameter: ${snakeToDisplayString(paramName)}`,
          type: 'error',
        }));
      },
    },
  );
};
